import React from 'react';
import { Grid, CircularProgress, Button, Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CustomInputField from '../../custom/CustomInputField';
import { OrganizationDetailsValidation } from './validation';
import LogoSection from './LogoSection';

const DetailsSection = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} md={4}>
        <Box pb={3}>
          <LogoSection />
        </Box>
        <Formik
          enableReinitialize
          validationSchema={OrganizationDetailsValidation}
          initialValues={{
            orgName: '',
            web: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            taxId: '',
            guidestar: '',
          }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Nonprofit Name"
                    name="orgName"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Website"
                    name="web"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trim
                    fullWidth
                    label="Nonprofit Tax id"
                    name="taxId"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    label="Link to Guidestar (if applicable)"
                    name="guidestar"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Save Details'
                    )}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default DetailsSection;
