import React from 'react';
import {
  Typography,
  Box,
  makeStyles,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { navigate } from '@reach/router';
import { enterprises } from '../../../../state';
import SEO from '../../../seo';
import CreateMember from './CreateMember';

const columns = [
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 170,
    format: (row) => (
      <Typography variant="body1">{row.account.firstName}</Typography>
    ),
  }, // Giftcard status cannot be toggled },
  {
    id: 'account',
    minWidth: 170,
    label: 'Last Name',
    format: (row) => (
      <Typography variant="body1">{row.account.lastName}</Typography>
    ),
  },
  {
    id: 'account',
    label: 'Email Address',
    format: (row) => (
      <Typography variant="body1">{row.account.email}</Typography>
    ),
  },
  {
    id: 'account',
    label: 'Phone #',
    format: (row) => (
      <Typography variant="body1">{row.account.phone}</Typography>
    ),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {},
});

const Members = ({ ...nav }) => {
  const classes = useStyles();
  const { id } = nav;
  const [page, setPage] = React.useState(0);
  const [members, setMembers] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [keyword, setKeyword] = React.useState('');
  const [itemSelected, setItemSelected] = React.useState({});
  const [headers] = React.useState(columns);
  const dispatch = useDispatch();
  const enterprise = useSelector(enterprises.selectors.selectCurrentEnterprise);
  const enterpriseId = enterprise.id;
  const handleGetList = async (params) => {
    // eslint-disable-next-line no-param-reassign
    params.id = params.id || enterpriseId;
    const response = await dispatch(
      enterprises.actions.getMemberByEnterpriseId(params)
    );
    if (response.payload?.data) {
      setMembers(response.payload.data);
      setTotal(response.payload.total);
    } else {
      navigate('/dashboard/enterprises');
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);

  // On First Load
  React.useEffect(() => {
    handleGetList({
      page: page + 1,
      enterpriseId: id || enterpriseId,
      limit: rowsPerPage,
    });
  }, []);

  const openModal = () => {
    setItemSelected({});
    setIsOpen(true);
  };

  const closeModal = async (isRefresh) => {
    setIsOpen(false);
    if (isRefresh) {
      handleGetList({
        page: page + 1,
        enterpriseId: id || enterpriseId,
        limit: rowsPerPage,
      });
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    handleGetList({
      page: newPage + 1,
      enterpriseId: id,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const pageLimit = event.target.value;
    setRowsPerPage(pageLimit);
    setPage(0);
    handleGetList({
      page: page + 1,
      enterpriseId: id,
      limit: pageLimit,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = async (row, type, value) => {
    const item = row;
    const enterpriseMemberOwner = item.enterpriseMembers.find(
      (i) => i.role === 4
    );
    if (enterpriseMemberOwner && enterpriseMemberOwner.account) {
      item.firstName = enterpriseMemberOwner.account.firstName;
      item.lastName = enterpriseMemberOwner.account.lastName;
      item.email = enterpriseMemberOwner.account.email;
      item.accountId = enterpriseMemberOwner.account.id;
    }

    setItemSelected(item);
    setIsOpen(true);
  };

  const handleCreate = () => {
    openModal();
  };

  const handleTextFieldChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    handleGetList({
      page: 1,
      enterpriseId: id,
      keyword,
      limit: rowsPerPage,
    });
  };

  // Get Merchant Data.
  return (
    <>
      <SEO title="Enterprise Customers - Users" />
      <Box pb={2}>
        <Typography variant="h5">
          Enterprise Customers - {enterprise.name} - Users
        </Typography>
      </Box>

      <CreateMember
        isOpen={isOpen}
        closeModal={closeModal}
        itemSelected={itemSelected}
        enterpriseId={id}
      />
      <Grid container md={8} xs={12} lg={8}>
        <Grid container md={12}>
          <Grid container item md={6} xs={6}>
            <Grid item md={8} xs={8}>
              <TextField
                // required
                // type="password"
                placeholder="User Name"
                value={keyword}
                onChange={handleTextFieldChange}
                name="name"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={4} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                size="small"
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" md={6} xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleCreate}
              size="small"
            >
              ADD USER
            </Button>
          </Grid>
        </Grid>
        <Grid md={8} xs={12} lg={8}>
          <Box py={2} style={{ width: '100%' }}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headers.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {headers.map((header) => {
                            const value = row[header.id];
                            return (
                              <TableCell key={header.id} align={header.align}>
                                {header.format
                                  ? header.format(row, value, handleChange)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

Members.propTypes = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Members);
