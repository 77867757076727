export const PartnerEnum = {
  BHN: 'BHN',
  Square: 'Square',
  Clover: 'Clover',
  Shopify: 'Shopify',
};

export const FranchiseType = {
  GiftCard: 0,
  Loyalty: 1,
  Coupon: 2,
  Membership: 3,
};
export default {};
