import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import SEO from '../../seo';
import { alert, business, memberships } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const SyncSquareMembers = () => {
  const [, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));

  const [businessData, setBusinessData] = React.useState({});
  const [counts, setCounts] = React.useState({
    pintuna: 0,
    partner: 0,
  });

  useRef(null);

  const updateMerchantCode = async (id, ctx) => {
    if (!id) return;
    const res = await dispatch(business.actions.getBusiness(id));
    if (res.payload) {
      setBusinessData(res.payload);
      ctx.setFieldValue('businessName', res.payload.name);
      ctx.setFieldValue('pos', res.payload.partner);
      setIsLoading(false);
    }
  };
  const getCounts = async () => {
    if (businessData?.id) {
      const res = await dispatch(
        memberships.actions.getSubscriptions(businessData.id)
      );
      console.log(res.payload);
      if (res.payload) {
        setCounts(res.payload);
      }
    }
  };

  useEffect(() => {
    getCounts();
  }, [businessData]);
  const colsTitles = ['Second Merchant Id', 'Create Date'];

  const rowKeys = ['code', 'createdAt'];
  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  headers.push({ label: 'Merchant Name', key: 'businessName' });

  const syncMembers = async () => {
    const response = await dispatch(
      memberships.actions.syncMembers({
        businessId: businessData.id,
      })
    );
    if (response.error) {
      openAlert({
        message: response.error.message || 'Invalid merchant Id',
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: 'Pricing rule is created successfully!',
        severity: 'success',
      });
    }
  };

  return (
    <>
      <Grid>
        <SEO title="Map Merchant IDs" />
        <Box pb={2}>
          <Typography variant="h5">Square Merchant - Sync Members</Typography>
        </Box>
        <Divider />
        <Box py={2}>
          <Grid container spacing={2} style={{ flexGrow: 1 }}>
            <Grid item md={6} xs={12}>
              <Formik
                validationSchema={Yup.object({
                  merchantId: Yup.string().required('Required'),
                  // code: Yup.string().required('Required'),
                })}
                initialValues={{
                  merchantId: 'a6a900a9-5661-4721-a035-59469bd3027d', // a6a900a9-5661-4721-a035-59469bd3027d
                }}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            required
                            type="text"
                            placeholder="Enter Primary POS Merchant ID"
                            name="merchantId"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              updateMerchantCode(values.merchantId, {
                                setFieldValue,
                              })
                            }
                            size="large"
                          >
                            Sync Members
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            disabled
                            type="text"
                            placeholder="Business Name"
                            name="businessName"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            disabled
                            placeholder="POS System"
                            name="pos"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {counts?.partnerIds && (
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Typography>
                              Current # of members in Pintuna
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              style={{
                                border: '1px solid #ccc',
                                height: 38,
                                width: 100,
                              }}
                              p={1}
                            >
                              {counts?.pintuna}
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              Current # of members in Square
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              style={{
                                border: '1px solid #ccc',
                                height: 38,
                                width: 100,
                              }}
                              p={1}
                            >
                              {counts?.partner}
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              onClick={() => syncMembers()}
                              color="primary"
                              variant="contained"
                            >
                              Sync Members
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default SyncSquareMembers;
