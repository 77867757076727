import React from 'react';

import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import CompanyProfile from './CompanyProfile';
import MemberList from './Members/MemberList';
import MapEnterpriseMerchants from './MapMerchants/MapEnterpriseMerchants';

const Enterprises = () => {
  return (
    <Router basepath={withPrefix('dashboard/enterprises')}>
      <CompanyProfile path="/" />
      <MemberList path="members/:id" />
      <MapEnterpriseMerchants path="merchants/:id" />
      <MapEnterpriseMerchants path="merchants" />
      <MemberList path="members" />
    </Router>
  );
};

export default Enterprises;
