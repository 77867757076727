import React from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { alert, enterprises } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const CreateCompany = ({ isOpen, closeModal, openAlert, itemSelected }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    address: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    city: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    state: Yup.string().required('Required'),
    zip: Yup.string().nullable(),
    country: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .required('Please enter website'),
    firstName: Yup.string()
      .required('Required')
      .max(100, 'Password is too long.'),
    lastName: Yup.string()
      .required('Required')
      .max(100, 'Password is too long.'),
    email: Yup.string().email('Invalid email'),
    password: Yup.string()
      .min(6, 'Password is too short.')
      .when('id', {
        is: (id) => !id,
        then: Yup.string().required('Required'),
      }),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const body = (
    <Box py={0}>
      <Grid container justifyContent="center">
        <Grid item md={8} xs={10}>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              id: itemSelected.id || '',
              name: itemSelected.name || '',
              address: itemSelected.address || '',
              city: itemSelected.city || '',
              state: itemSelected.state || '',
              firstName: itemSelected.firstName || '',
              lastName: itemSelected.lastName || '',
              zipcode: itemSelected.zipcode || '',
              country: itemSelected.country || '',
              website: itemSelected.website || '',
              email: itemSelected.email || '',
              confirmPassword: itemSelected.confirmPassword || '',
              password: itemSelected.password || '',
            }}
            onSubmit={async (values, { resetForm }) => {
              try {
                setLoading(true);
                let response = '';
                if (values.id) {
                  response = await dispatch(
                    enterprises.actions.updateEnterprise(values)
                  );
                } else {
                  response = await dispatch(
                    enterprises.actions.setEnterprise(values)
                  );
                }
                if (response.payload) {
                  let message = 'Enterprise created successfully';
                  if (values.id) {
                    message = 'Enterprise updated successfully';
                  }
                  openAlert({
                    message,
                    severity: 'success',
                  });
                  resetForm();
                  closeModal(true);
                } else if (response.error && response.error.message) {
                  openAlert({
                    message:
                      response.error.message || 'Oops something went wrong',
                    severity: 'error',
                  });
                }
                setLoading(false);
              } catch (error) {
                setLoading(false);
              }

              return true;
            }}
          >
            {({ handleSubmit }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        size="small"
                        label="Business Name"
                        type="text"
                        placeholder="Business Name"
                        name="name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        size="small"
                        label="Address"
                        type="text"
                        placeholder="Address"
                        name="address"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        size="small"
                        type="text"
                        label="City"
                        placeholder="City"
                        name="city"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="State"
                        placeholder="State"
                        name="state"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        size="small"
                        label="Zipcode"
                        type="text"
                        placeholder="Zipcode"
                        name="zip"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Country"
                        placeholder="Country"
                        name="country"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Website"
                        placeholder="Website"
                        name="website"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        placeholder="First Name"
                        size="small"
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Last name"
                        placeholder="Last Name"
                        name="lastName"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Email"
                        placeholder="Email"
                        name="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Box mb={1}>
                      <Typography
                        variant="body1"
                        style={{ fontSize: '10px', marginLeft: '10px' }}
                      >
                        * We recommend using an e-mail associated with this
                        business
                      </Typography>
                    </Box>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="small"
                        label="Password"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="password"
                        placeholder="Confirm password"
                        name="confirmPassword"
                        size="small"
                        label="Confirm password"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={loading}
                        onClick={handleSubmit}
                        size="small"
                      >
                        Register
                      </Button>
                    </Grid>
                    <Typography
                      variant="body1"
                      style={{ fontSize: '10px', marginLeft: '10px' }}
                    >
                      By create an account, you agree to Pintuna`s{' '}
                      <span style={{ color: 'blue' }}>Terms of Use</span> and{' '}
                      <span style={{ color: 'blue' }}>Privacy Policy</span>
                    </Typography>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle textAlign="center">Enterprise Registration</DialogTitle>
      <DialogContent dividers>{body}</DialogContent>
    </Dialog>
  );
};

CreateCompany.propTypes = {
  openAlert: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemSelected: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
