/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Divider, Grid, Button } from '@material-ui/core';

// import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getOr } from 'lodash/fp';
import SEO from '../../seo';
import { TransactionTable } from '../../custom';
import { alert, business } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const MapCloverMerchants = () => {
  const [merchantCodes, setMerchantCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const makeMerchantCode = () => {
  //   let result = '';
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  //   const charactersLength = characters.length;
  //   for (let i = 0; i < 16; i += 1) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // };
  const updateMerchantCode = async (payload, ctx) => {
    const res = await dispatch(business.actions.getMerchantCode(payload));
    if (res.payload) {
      setMerchantCodes(getOr([], ['payload', 'items'], res));
      ctx.setFieldValue('businessName', res.payload.businessName);
      ctx.setFieldValue('pos', res.payload.partner);
      setIsLoading(false);
    }
  };
  const generateMerchantCode = async (values, ctx) => {
    setIsLoading(true);
    // const code = makeMerchantCode();
    // ctx.setFieldValue('code', code);
    const response = await dispatch(
      business.actions.createMerchantCode({
        ...values,
        // code,
      })
    );
    if (response.error) {
      openAlert({
        message: response.error.message || 'Invalid merchant Id',
        severity: 'error',
      });
      setIsLoading(false);
    } else if (response.payload) {
      openAlert({
        message: 'Merchant Code created successfully!',
        severity: 'success',
      });
      await updateMerchantCode(
        { merchantId: response.payload.merchantId },
        ctx
      );
    }
  };
  useEffect(() => {
    // updateMerchantCode();
  }, []);
  const colsTitles = ['Second Merchant Id', 'Create Date'];
  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
  };

  const rowKeys = ['code', 'createdAt'];
  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  headers.push({ label: 'Merchant Name', key: 'businessName' });

  return (
    <>
      <SEO title="Map Merchant IDs" />
      <Box pb={2}>
        <Typography variant="h5">Map Merchant IDs</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={8} xs={12}>
            <Formik
              validationSchema={Yup.object({
                merchantId: Yup.string().required('Required'),
                // code: Yup.string().required('Required'),
              })}
              initialValues={{
                merchantId: '',
                code: '',
                name: '',
                partner: '',
              }}
              onSubmit={(values, ctx) => generateMerchantCode(values, ctx)}
            >
              {({ handleSubmit, values, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter Primary POS Merchant ID"
                          name="merchantId"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            updateMerchantCode(
                              {
                                merchantId: values.merchantId,
                              },
                              { setFieldValue }
                            )
                          }
                          size="large"
                        >
                          Get List of Mapped Merchant IDs
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          placeholder="Enter Secondary POS Merchant ID"
                          name="code"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          size="large"
                        >
                          Map To Primary Merchant ID
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          disabled
                          type="text"
                          placeholder="Business Name"
                          name="businessName"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          disabled
                          placeholder="POS System"
                          name="pos"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid item md={6} xs={12} lg={6}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={merchantCodes}
            mods={mods}
            isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
    </>
  );
};

export default MapCloverMerchants;
