/* eslint-disable no-empty */
import React from 'react';
import {
  Typography,
  Box,
  makeStyles,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import { navigate } from '@reach/router';
import { enterprises } from '../../../../state';
import SEO from '../../../seo';
import CreateMapMerchant from './CreateMapMerchant';

const columns = [
  { id: 'categoryName', label: 'Category', minWidth: 170 },
  { id: 'businessName', label: 'Merchant Name', minWidth: 170 },
  { id: 'giftCardName', label: 'Gift Card Name', minWidth: 170 },
  { id: 'city', label: 'City' },
  { id: 'state', label: 'State' },
  { id: 'country', label: 'Country' },
  {
    id: 'isEnterprise',
    label: 'Enterprise GC Define',
    minWidth: 170,
    format: (row, value) => <Switch checked={value} />,
  },
  {
    id: 'status',
    label: 'Unmap Merchant',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'unmapMerchant', value)}
      />
    ),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {},
});

const MapMerchants = ({ openAlert, ...nav }) => {
  const classes = useStyles();
  const { id } = nav;
  const [page, setPage] = React.useState(0);
  const [merchants, setMerchants] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [keyword, setKeyword] = React.useState('');
  const [itemSelected, setItemSelected] = React.useState({});
  const [headers] = React.useState(columns);
  const dispatch = useDispatch();
  const enterprise = useSelector(enterprises.selectors.selectCurrentEnterprise);
  const enterpriseId = enterprise.id;
  const handleGetList = async (params) => {
    // eslint-disable-next-line no-param-reassign
    params.id = params.id || enterpriseId;
    const response = await dispatch(
      enterprises.actions.getEnterpriseAssociateByEnterpriseId(params)
    );

    const items = [];
    if (response.payload?.data) {
      // eslint-disable-next-line array-callback-return
      response.payload?.data?.map((i) => {
        const item = {
          categoryName: i.business.category.name,
          businessName: i.business.name,
          giftCardName: i.giftCardConfig.name,
          isEnterprise: i.giftCardConfig.isEnterprise,
          businessId: i.business.id,
          id: i.id,
          status: !i.status,
          city: i.enterprise.city,
          state: i.enterprise.state,
          country: i.enterprise.country,
        };
        items.push(item);
      });

      setMerchants(items);
      setTotal(response.payload.total);
    } else {
      navigate('/dashboard/enterprises');
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  // On First Load
  React.useEffect(() => {
    handleGetList({
      page: page + 1,
      enterpriseId: id || enterpriseId,
      limit: rowsPerPage,
    });
  }, []);

  const openModal = () => {
    setItemSelected({});
    setIsOpen(true);
  };

  const closeModal = async (isRefresh) => {
    setIsOpen(false);
    if (isRefresh) {
      handleGetList({
        page: page + 1,
        enterpriseId: id,
        limit: rowsPerPage,
      });
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    handleGetList({
      page: newPage + 1,
      enterpriseId: id,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const pageLimit = event.target.value;
    setRowsPerPage(pageLimit);
    setPage(0);
    handleGetList({
      page: page + 1,
      enterpriseId: id,
      limit: pageLimit,
    });
  };

  const handleChangeStatus = async (eId) => {
    try {
      const formData = { id: eId };
      const response1 = await dispatch(
        enterprises.actions.updateStatusEnterpriseAssociate(formData)
      );

      if (response1.payload) {
        const message = 'Unmap Merchant success';
        // console.log(111, response.payload);

        handleGetList({
          page: 1,
          enterpriseId: id,
          limit: rowsPerPage,
          key: Math.random(),
        });
        openAlert({
          message,
          severity: 'success',
        });
      }
    } catch (error) {}
  };
  // eslint-disable-next-line no-unused-vars
  const handleChange = async (row, type, value) => {
    handleChangeStatus(row.id);
  };

  const handleCreate = () => {
    openModal();
  };

  const handleTextFieldChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    handleGetList({
      page: 1,
      enterpriseId: id,
      keyword,
      limit: rowsPerPage,
    });
  };

  // Get Merchant Data.
  return (
    <>
      <SEO title="Enterprise Customers - Map merchants" />
      <Box pb={2}>
        <Typography variant="h5">
          Enterprise Customers - {enterprise.name} - Map merchants
        </Typography>
      </Box>

      {isOpen && (
        <CreateMapMerchant
          isOpen={isOpen}
          closeModal={closeModal}
          itemSelected={itemSelected}
          enterpriseId={id}
        />
      )}
      <Grid container justifyContent="flex-start" md={12}>
        <Grid container md={12}>
          <Grid container item md={6} xs={6} spacing={2}>
            <Grid item>
              <TextField
                placeholder="Merchant Name"
                value={keyword}
                onChange={handleTextFieldChange}
                name="name"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" md={6} xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleCreate}
              size="small"
            >
              ADD MERCHANT
            </Button>
          </Grid>
        </Grid>
        <Grid md={10} xs={12} lg={10}>
          <Box py={2} style={{ width: '100%' }}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headers.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {merchants.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {headers.map((header) => {
                            const value = row[header.id];
                            return (
                              <TableCell key={header.id} align={header.align}>
                                {header.format
                                  ? header.format(row, value, handleChange)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

MapMerchants.propTypes = { openAlert: PropTypes.func.isRequired };

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapMerchants);
