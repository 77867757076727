import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

import SEO from '../../seo';
import ComingSoon from '../ComingSoon';

const EditMerchants = () => {
  return (
    <>
      <SEO title="Edit Merchants" />
      <Box pb={2}>
        <Typography variant="h5">Edit Merchants</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <ComingSoon />
      </Box>
    </>
  );
};

export default EditMerchants;
