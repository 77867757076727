import * as Yup from 'yup';

const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;
const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

const UserValidation = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  });
const OrganizationDetailsValidation = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  });
const AddressValidation = () =>
  Yup.object().shape({
    line1: Yup.string().min(2, 'Too Short!').required('Required'),
    city: Yup.string().min(2, 'Too Short!').required('Required'),
    state: Yup.string().min(2, 'Too Short!').required('Required'),
    postal_code: Yup.string()
      .typeError('Numbers only')
      .matches(zipRegExp, 'Incorrect Zipcode')
      .test(
        'len',
        'Must be exactly 5 characters',
        (val) => !val || (val && val.toString().length === 5)
      )
      .required('Required'),
  });
export { UserValidation, OrganizationDetailsValidation, AddressValidation };
