import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { TextField, RadioGroup } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
// import getSymbolFromCurrency from 'currency-symbol-map';

import { Close } from '@material-ui/icons';
import { alert, memberships, business } from '../../../../state';
import CustomInputField from '../../../custom/CustomInputField';

const AddPrimaryMerchantModal = ({ isOpen, closeModal }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const formikRef = React.useRef(null);

  const initValues = {
    businessId: '',
    businessName: '',
    membershipPlanId: '',
    discountCodeType: '0',
  };
  // const { currency } = useSelector(business.selectors.selectMerchantsList);
  // const currencySymbol = getSymbolFromCurrency(currency);

  const [plans, setPlans] = React.useState([]);
  const businessData = useSelector(business.selectors.selectBusinessData);
  const isLoading = useSelector(business.selectors.selectIsLoading);

  useEffect(() => {
    const fn = async () => {
      formikRef?.current?.setFieldValue('businessName', businessData.name);
      const res = await dispatch(
        memberships.actions.getMembershipPlans(businessData.id)
      );

      if (!res.error && res.payload) {
        setPlans(res.payload);
      } else {
        openAlert({
          message: res.payload || 'Merchant is not found',
          severity: 'error',
        });
      }
    };
    if (businessData?.id) {
      fn();
    }
  }, [businessData]);

  const validationSchema = Yup.object().shape({
    businessId: Yup.string().required('Please select a merchant'),
    membershipPlanId: Yup.string().required('Please select a plan'),
  });

  const getBusiness = async (value) => {
    if (!value) return;
    const res = await dispatch(business.actions.getBusiness(value));
    if (res.error) {
      openAlert({
        message: res.error?.message || 'Merchant is not found',
        severity: 'error',
      });
    }
  };

  const body = (
    <Box>
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={initValues}
        onSubmit={async (values, { resetForm }) => {
          try {
            const response = await dispatch(
              memberships.actions.createPrimaryMerchant({
                businessId: values.merchantId,
                membershipPlanId: values.membershipPlanId,
              })
            );

            if (response.payload) {
              openAlert({
                message: ' Primary merchant has been added successfully!',
                severity: 'success',
              });
              resetForm();
              dispatch(memberships.actions.getPrimaryMembershipsList());
              closeModal(true);
            } else if (response.error) {
              openAlert({
                message:
                  response.error.message || `Can't create primary merchant`,
                severity: 'error',
              });
            }
            setLoading(false);
          } catch (error) {
            // setLoading(false);
          }

          return true;
        }}
      >
        {({ setFieldValue, handleSubmit, values }) => {
          const handleSelectPlan = (event, val) => {
            setFieldValue('membershipPlanId', val.props.value);
            // const plan = plans.find((p) => p.id === val.props.value) || {};
            // setPlan(plan);
          };

          return (
            <Form>
              <Box mt={2}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      required
                      type="text"
                      placeholder="Enter Primary POS Merchant ID"
                      id="businessId"
                      name="businessId"
                      variant="outlined"
                      InputProps={{
                        onBlur: (e) => getBusiness(e.target.value),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      disabled
                      type="text"
                      placeholder="Business Name"
                      name="businessName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Select}
                      as="select"
                      autoWidth={false}
                      fullWidth
                      required
                      label="Select Plan"
                      name="membershipPlanId"
                      variant="outlined"
                      onChange={(event, val) => handleSelectPlan(event, val)}
                    >
                      <MenuItem value={undefined}>---</MenuItem>
                      {plans.map(
                        (opt) =>
                          opt && (
                            <MenuItem value={opt.id} key={opt.id}>
                              {opt.name}
                            </MenuItem>
                          )
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={RadioGroup}
                      name="type"
                      row
                      label="Code Type"
                      onChange={(e, val) =>
                        setFieldValue('discountCodeType', val)
                      }
                    >
                      <Grid item>
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              value={0}
                              color="primary"
                              name="discountCodeType"
                              checked={values.discountCodeType === '0'}
                            />
                          }
                          label="QR Code"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio
                              value={1}
                              color="primary"
                              name="discountCodeType"
                              checked={values.discountCodeType === '1'}
                            />
                          }
                          label="Bar Code"
                        />
                      </Grid>
                    </Field>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={7} />
                  <Grid item>
                    <Button
                      style={{ marginRight: 12 }}
                      onClick={handleSubmit}
                      disabled={isLoading || loading}
                      variant="contained"
                      color="primary"
                    >
                      {isLoading || loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>Primary Merchant</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddPrimaryMerchantModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddPrimaryMerchantModal;
