/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import SEO from '../../seo';
import { memberships } from '../../../state';
import { TransactionTable } from '../../custom';
import AddSecondaryMerchantModal from './Modal/AddSecondaryMerchantModal';

const colsTitles = [
  'Merchant Name',
  'Square Merchant Id',
  'Pintuna Merchant Id',
  'Program Name',
  'Pricing Rule Id',
  'Discount Offered',
  'Enable',
];

const rowKeys = [
  'secondBusiness.name',
  'secondBusiness.merchantId',
  'secondBusiness.id',
  'membershipPlan.name',
  'partnerPricingRuleId',
  'discount',
  'status',
];

const SecondaryMerchant = () => {
  const [params] = React.useState({ limit: 10, page: 1 });
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      memberships.actions.getPrimaryMembershipsList({
        limit: 10,
        page: 1,
        sharingStatus: 1,
      })
    );
    dispatch(memberships.actions.getSecondaryMembershipsList(params));
  }, [params]);

  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const membershipPlans = useSelector(
    memberships.selectors.selectSecondaryMemberships
  );

  useEffect(() => {}, [membershipPlans]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const updateStatus = async (row) => {
    await dispatch(
      memberships.actions.updateSecondaryMerchant({
        id: row.id,
        status: row.status === 1 ? 2 : 1,
      })
    );
    dispatch(memberships.actions.getSecondaryMembershipsList(params));
  };
  const mods = {
    status: (id, row) =>
      row.status === 1 ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateStatus(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateStatus(row)}
        />
      ),
  };

  return (
    <>
      <SEO title="Membership discount" />
      <AddSecondaryMerchantModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
      <Grid md={12} xl={10}>
        <Box py={2}>
          <Button
            disabled={isLoading}
            onClick={() => handleOpenModal(true)}
            variant="contained"
            color="primary"
          >
            Add Secondary Merchant
          </Button>

          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={membershipPlans?.data || []}
            mods={mods}
            isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>
    </>
  );
};

export default SecondaryMerchant;
