/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { CardGiftcard, Business } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import SEO from '../../seo';
import { InfoCard, TransactionTable } from '../../custom';
import SummaryTable from './SummaryTable';
import { stats } from '../../../state';

const useStyles = makeStyles(() => ({
  cardOut: {
    padding: '20px',
    justifyContent: 'center',
    width: '100%',
    marginTop: '40px',
  },
  card: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
}));

const StoreCredits = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const colsTitles = [
    'Business Name',
    'Currency',
    'Cards Sold',
    'Total Value',
    'Average Value',
  ];
  const rowKeys = [
    'businessName',
    'currency',
    'cardsSold',
    'totalValue',
    'averageValue',
  ];
  const mods = {
    totalValue: (val, row) =>
      `${getSymbolFromCurrency(row.currency) || ''}${val.toFixed(2)}`,
    averageValue: (val, row) =>
      `${getSymbolFromCurrency(row.currency) || ''}${val.toFixed(2)}`,
  };

  const {
    storeCreditsCount,
    totalStoreCreditsSold,
    // summaryMerchantsCount = 0,
    storeCreditsSummary = [],
    storeCreditsByBusiness = [],
  } = data;
  const [params, setParams] = useState({ page: 1, limit: 10 });

  const pagination = {
    total: storeCreditsByBusiness.length,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, p) => setParams({ ...params, page: p + 1 }),
    handleChangeRowsPerPage: (event) =>
      setParams({ ...params, pageSize: event.target.value, page: 1 }),
  };
  // On First Load
  React.useEffect(() => {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = date;

    dispatch(
      stats.actions.getStoreCreditStats({
        startDate: start,
        endDate: end,
        limit: params.limit,
        page: params.page,
      })
    );
  }, []);

  const refreshData = (range) => {
    dispatch(
      stats.actions.getStoreCreditStats({
        startDate: range.startDate,
        endDate: range.endDate,
        limit: params.limit,
        page: 1,
      })
    );
  };

  return (
    <>
      <SEO title="Dashboard - Store Credits" />
      <Box pb={2}>
        <Typography variant="h5">Dashboard - Store Credits</Typography>
      </Box>
      <Divider />
      <Grid container spacing={2} justifyContent="center" xs="auto" mt={2}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sm={6}
            md={3}
            className={classes.cardOut}
            spacing={3}
            p={2}
          >
            <InfoCard
              label="# of Merchants"
              icon={<Business fontSize="large" />}
              isLoading={false}
              onClick={() => {}}
            >
              {storeCreditsCount}
            </InfoCard>
          </Grid>
          <Grid xs={12} sm={6} md={3} className={classes.cardOut}>
            <InfoCard
              label="STORE CREDITS SOLD"
              icon={<CardGiftcard fontSize="large" />}
              isLoading={false}
              onClick={() => {}}
            >
              {totalStoreCreditsSold}
            </InfoCard>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={classes.cardOut}>
            <SummaryTable
              label="Store Credits Summary"
              isLoading={false}
              data={storeCreditsSummary}
              refreshData={refreshData}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid className={classes.cardOut} md="12" lg="10" xl="8">
            <Box>
              <TransactionTable
                cols={colsTitles}
                rowKeys={rowKeys}
                data={(storeCreditsByBusiness || []).slice(
                  (params.page - 1) * params.limit,
                  params.page * params.limit
                )}
                mods={mods}
                uniqueIdBy="code"
                noDataMsg="Hmm... Looks like there is no data."
                pagination={pagination}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

StoreCredits.propTypes = {
  data: PropTypes.shape({
    totalStoreCreditsSold: PropTypes.number,
    storeCreditsCount: PropTypes.number,
    summaryMerchantsCount: PropTypes.number,
    storeCreditsSummary: PropTypes.objectOf(PropTypes.any),
    storeCreditsByBusiness: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  data: stats.selectors.selectStoreCreditStats(state),
});

export default connect(mapStateToProps)(StoreCredits);
