/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import moment from 'moment/moment';
import { useDebounce } from 'use-debounce';
import { TransactionTable } from '../../../custom';
import AddSecondaryFranchiseModal from '../Modal/AddSecondaryFranchiseModal';
import franchises from '../../../../state/franchises';
import SyncFranchiseGiftCardModal from '../Modal/SyncFranchiseGiftCardModal';
import { alert, memberships } from '../../../../state';
import searchIcon from '../../../../images/search.png';
import ConfirmationDialog from '../../../custom/ConfirmDialog';
import { FranchiseType } from '../../../../utils/constants';
import MembershipFranchiseModal from '../Modal/MembershipFranchiseModal';
import syncIcon from '../../../../images/sync.png';

const colsTitles = [
  'Group / Franchise Name',
  'Secondary Merchant Name',
  'Secondary Merchant Id',
  'POS',
  'Pintuna - Secondary Merchant Id',
  'Primary Merchant Name',
  'Square - Primary Merchant Id',
  'Pintuna - Primary Merchant Id',
  'Enabled',
  'Sync',
  'Added On',
];

const rowKeys = [
  'franchise.name',
  'business.name',
  'business.merchantId',
  'business.partner',
  'business.id',
  'franchise.business.name',
  'franchise.business.merchantId',
  'franchise.business.id',
  'enabled',
  'id',
  'createdAt',
];

// eslint-disable-next-line react/prop-types
const SecondaryFranchise = ({ type = 0 }) => {
  const [params, setParams] = React.useState({ limit: 10, page: 1, type });
  const [isOpen, setIsOpen] = React.useState(false);
  const [openSync, setOpenSync] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const filteredRowKeys =
    type === FranchiseType.Membership
      ? rowKeys
      : rowKeys.filter((key) => key !== 'id');
  const filteredColsTitles =
    type === FranchiseType.Membership
      ? colsTitles
      : colsTitles.filter((key) => key !== 'Sync');
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  const [confirmation, setConfirmation] = useState(false);
  const [franchiseDetail, setFranchiseDetail] = useState(null);

  const getSecondaryMerchants = (p) =>
    dispatch(franchises.actions.getSecondaryMerchants(p || params));

  useEffect(() => {
    dispatch(
      franchises.actions.getFranchisesList({
        limit: 10,
        page: 1,
        type,
      })
    );
    getSecondaryMerchants(params);
  }, [params]);

  const [searchParams] = useDebounce(params, 700);

  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const secondaryFranchises = useSelector(
    franchises.selectors.selectSecondaryMerchant
  );

  const openFranchiseDetail = (row, autoSync = false) => {
    setFranchiseDetail({ ...row, autoSync });
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const updateEnabled = async (row) => {
    const res = await dispatch(
      franchises.actions.createSecondaryMerchant({
        businessId: row.business.id,
        franchiseId: row.franchise.id,
        primaryBusinessId: row.franchise?.business?.id,
        name: row.name,
        id: row.id,
        enabled: !row.enabled,
        type: row.franchise?.type,
      })
    );
    if (!res.error && res.payload) {
      openAlert({
        message: 'Secondary merchant is updated successfully',
        severity: 'success',
      });
    } else {
      openAlert({
        message: res.payload || "Can't update secondary merchant",
        severity: 'error',
      });
    }
    getSecondaryMerchants(params);
  };
  const mods = {
    id: (val, row) => (
      <IconButton
        onClick={() => openFranchiseDetail(row)}
        style={{ marginRight: -14 }}
      >
        <img
          src={syncIcon}
          alt="search"
          style={{
            width: 25,
            height: 25,
            paddingTop: 2,
            cursor: 'pointer',
          }}
        />
      </IconButton>
    ),
    enabled: (val, row) =>
      val ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          // onClick={() => updateEnabled(row)}
          onClick={() => setConfirmation(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          disabled={!row.franchise?.enabled}
          onClick={() => updateEnabled(row)}
        />
      ),
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
  };

  const closeSecondaryFranchiseModal = (item) => {
    setIsOpen(false);
    openFranchiseDetail(item, true);
  };

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
    getSecondaryMerchants(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    setParams(p);
    getSecondaryMerchants(p);
  };
  const pagination = {
    total: secondaryFranchises.total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const search = () => {
    setParams({
      ...params,
      keyword,
    });
  };

  const searchBox = useCallback(() => {
    return (
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item>
          <Button
            disabled={isLoading}
            onClick={() => handleOpenModal(true)}
            variant="contained"
            color="primary"
          >
            Add Secondary Merchant
          </Button>
        </Grid>
        {type === 1 && (
          <Grid item alignItem="center">
            <Typography style={{ color: 'red', fontWeight: 'bold' }}>
              Make sure the Loyalty App is enabled for the secondary merchants
              as well
            </Typography>
          </Grid>
        )}
        <Grid item>
          <TextField
            value={keyword}
            fullWidth
            placeholder="Merchant name, id, franchise, secondary name and id"
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
            style={{ minWidth: 300 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => search()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt="search"
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }, [search, params]);

  useEffect(() => {
    search();
  }, [keyword]);

  useEffect(() => {
    if (searchParams?.keyword === '' || searchParams?.keyword?.length > 2) {
      getSecondaryMerchants(params);
    }
  }, [searchParams]);

  return (
    <>
      <AddSecondaryFranchiseModal
        isOpen={isOpen}
        closeModal={(sync) => closeSecondaryFranchiseModal(sync)}
        type={type}
      />

      <SyncFranchiseGiftCardModal
        isOpen={openSync}
        closeModal={() => setOpenSync(false)}
        type={type}
      />

      <MembershipFranchiseModal
        franchiseBusiness={franchiseDetail}
        closeModal={() => setFranchiseDetail(null)}
      />
      <Grid xs="12" lg="12" xl="12">
        <Box py={2}>
          <TransactionTable
            cols={filteredColsTitles}
            rowKeys={filteredRowKeys}
            data={secondaryFranchises?.data || []}
            mods={mods}
            isLoading={isLoading}
            searchBox={searchBox}
            pagination={pagination}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>
      <ConfirmationDialog
        open={!!confirmation}
        setOpen={setConfirmation}
        title="Confirmation"
        isConfirmation
        content="Are you sure you want to deactivate the sync process?"
        actionOk={() => {
          updateEnabled(confirmation);
        }}
      />
    </>
  );
};

export default SecondaryFranchise;
