import React, { useEffect } from 'react';
import { Typography, Box, Button, Grid } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import Modal from 'react-modal';
import Modal from '@material-ui/core/Modal';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { alert, enterprises } from '../../../../state';
import SEO from '../../../seo';
import CustomInputField from '../../../custom/CustomInputField';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    boxShadow: theme.shadows[5],
    right: 'auto',
    width: '500px',
    height: '600px',
    bottom: 'auto',
    marginRight: '-50%',
    background: 'transparent',
    backgroundColor: '#fff',
    border: '1px solid #000',
    padding: theme.spacing(4, 0),
    overflow: 'scroll',
    // transform: 'translate(-50%, -50%)',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Modal.setAppElement('#___gatsby');

const initValues = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  confirmPassword: '',
  password: '',
  enterpriseId: '',
};

const CreateMember = ({
  isOpen,
  closeModal,
  openAlert,
  itemSelected,
  enterpriseId,
}) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState(initValues);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
    if (itemSelected.id) {
      setInitialValues(itemSelected);
    } else {
      setInitialValues(initValues);
    }
  }, [itemSelected]);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    phone: Yup.string()
      .required('Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    lastName: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    email: Yup.string().matches(
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invalid email format'
    ),
    password: Yup.string()
      .min(6, 'Password is too short - should be 6 chars minimum.')
      .when('id', {
        is: (id) => !id,
        then: Yup.string().required('Required'),
      }),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <SEO title="Enterprise Customers - Company Profile" />
      <Box pb={2}>
        <Typography
          variant="body1"
          style={{ textAlign: 'center', fontWeight: '700' }}
        >
          User Register
        </Typography>
      </Box>
      <Box py={0}>
        <Grid container justifyContent="center">
          <Grid item md={8} xs={10}>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={async (values, { resetForm, setErrors }) => {
                try {
                  setLoading(true);
                  const formData = { ...values, enterpriseId };

                  const response = await dispatch(
                    enterprises.actions.setMemberEnterprise(formData)
                  );
                  if (response.payload) {
                    const message = 'Created member success';

                    openAlert({
                      message,
                      severity: 'success',
                    });
                    resetForm();
                    closeModal(true);
                  } else if (response.error.message) {
                    setErrors({ email: 'Email already exists' });
                  }
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                }

                return true;
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            required
                            type="text"
                            size="small"
                            label="Last name"
                            placeholder="Last name"
                            name="lastName"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            required
                            type="text"
                            placeholder="First name"
                            size="small"
                            label="First name"
                            name="firstName"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          size="small"
                          label="Email"
                          placeholder="Email"
                          name="email"
                          variant="outlined"
                        />
                        <Typography
                          variant="body1"
                          style={{ fontSize: '10px', marginLeft: '10px' }}
                        >
                          * We recommend using an e-mail associated with this
                          bussiness
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          size="small"
                          label="Phone"
                          placeholder="Phone number"
                          name="phone"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="password"
                          placeholder="Password"
                          name="password"
                          size="small"
                          label="Password"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="password"
                          placeholder="Confirm password"
                          name="confirmPassword"
                          size="small"
                          label="Confirm password"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          loading={loading}
                          disabled={loading}
                          onClick={handleSubmit}
                          size="small"
                        >
                          Register
                        </Button>
                      </Grid>
                      <Typography
                        variant="body1"
                        style={{ fontSize: '10px', marginLeft: '10px' }}
                      >
                        By create an account, you agree to Pintuna`s{' '}
                        <span style={{ color: 'blue' }}>Terms of Use</span> and{' '}
                        <span style={{ color: 'blue' }}>Privacy Policy</span>
                      </Typography>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      // transparent
      onClose={closeModal}
      // style={customStyles}
      // contentLabel="Example Modal"

      // parentSelector={() => this.root.current}
    >
      {body}
    </Modal>
  );
};

CreateMember.propTypes = {
  openAlert: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemSelected: PropTypes.object.isRequired,
  enterpriseId: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateMember);
