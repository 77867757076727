import React from 'react';
import { Grid, CircularProgress, Button, Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CustomInputField from '../../custom/CustomInputField';
import { AddressValidation } from './validation';

const BankInfoSection = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} md={4}>
        <Formik
          enableReinitialize
          validationSchema={AddressValidation}
          initialValues={{
            bankName: '',
            bankAccount: '',
            routingNumber: '',
          }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Bank name"
                    name="bankName"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Account number"
                    name="bankAccount"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    label="Routing number"
                    name="routingNumber"
                    variant="outlined"
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  size="large"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Save Banking Details'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default BankInfoSection;
