import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import SEO from '../../seo';
import { stats } from '../../../state';
import { TransactionTable } from '../../custom';

const colsTitles = [
  'Nonprofit name',
  'Organization ID',
  'Cards sold',
  'Funds raised',
  'Total Payout',
  'Balance',
];

const rowKeys = [
  'name',
  'code',
  'totalCardsSold',
  'totalFundsRaised',
  'totalPayout',
  'totalBalance',
];

const mods = {
  totalFundsRaised: (val) => `$${val}`,
  totalPayout: (val) => `$${val.toFixed(2)}`,
  totalBalance: (val) => `$${val.toFixed(2)}`,
  totalCardsSold: (val) => `${val} cards`,
};

const NonprofitReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => stats.selectors.selectOrgsReport(state));
  React.useEffect(() => {
    if (isEmpty(data)) {
      dispatch(stats.actions.getOrgsReport());
    }
  }, []);

  const isLoading = useSelector((state) =>
    stats.selectors.selectIsLoading(state)
  );

  return (
    <>
      <SEO title="Funds Raised" />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data}
          mods={mods}
          isLoading={isLoading}
          uniqueIdBy="code"
          noDataMsg="Hmm... Looks like there is no data."
        />
      </Box>
    </>
  );
};

export default NonprofitReport;
