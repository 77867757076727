import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import DiscountCode from './DiscountCode';
import MembershipSync from './MembershipSync';
import LoyaltySync from './LoyaltySync';
import CustomerInfo from './CustomerInfo';

const Misc = () => {
  return (
    <Router basepath={withPrefix('dashboard/misc')}>
      <DiscountCode path="/discount-code" />
      <MembershipSync path="/membership" />
      <LoyaltySync path="/loyalty" />
      <CustomerInfo path="/customer" />
    </Router>
  );
};

export default Misc;
