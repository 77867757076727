import React, { useState } from 'react';
import { Box, Divider, Tabs } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import { TabContext, TabPanel } from '@material-ui/lab';
import StyledTab from '../../custom/StyledTab';
import MembershipSyncCcof from './MembershipSyncCcof';
import MembershipCancel from './MembershipCancel';

const MembershipSync = () => {
  const [formTab, setTab] = useState(0);

  return (
    <Box>
      <Box>
        <Tabs
          value={formTab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          TabIndicatorProps={{ style: { background: '#0969da' } }}
          aria-label="Select settings tab"
        >
          <StyledTab
            disableRipple
            id="primary"
            aria-controls="values"
            label="Get CCOF ID"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Schedule Cancellation"
          />
        </Tabs>
      </Box>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0}>
            {formTab === 0 ? <MembershipSyncCcof /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1}>
            {formTab === 1 ? <MembershipCancel /> : null}
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </Box>
  );
};

export default MembershipSync;
