import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import MapCloverMerchants from './MapCloverMerchants';
import SharedMemberships from './SharedMembership';
import MapSquareGiftCard from './Franchise/MapSquareGiftCard';
import MapSquareLoyalty from './Franchise/MapSquareLoyalty';
import MapSquareCoupon from './MapSquareCoupon';
import MembershipFranchise from './MembershipFranchise';

const GiftCardConfigs = () => {
  return (
    <Router basepath={withPrefix('dashboard/map-merchants')}>
      <MapCloverMerchants path="/" />
      {/* <MapCloverMerchants path="/clover" /> */}
      <SharedMemberships path="/shared-membership" />
      <MembershipFranchise path="/membership" />
      <MapSquareCoupon path="/coupon" />
      <MapSquareGiftCard path="/giftCard" />
      <MapSquareLoyalty path="/loyalty" />
    </Router>
  );
};

export default GiftCardConfigs;
