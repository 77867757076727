import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tabs,
  Typography,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { Close, Sync } from '@material-ui/icons';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import { TabContext, TabPanel } from '@material-ui/lab';
import franchises from '../../../../state/franchises';
import { TransactionTable } from '../../../custom';
import { alert } from '../../../../state';
import StyledTab from '../../../custom/StyledTab';

const MembershipFranchiseModal = ({ franchiseBusiness, closeModal }) => {
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [membershipDiscounts, setMembershipDiscounts] = useState([]);
  const [membershipLists, setMemberships] = useState({ data: [], count: 0 });
  const [membershipLoading, setMembershipLoading] = useState(false);
  const syncingRef = useRef(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  const [formTab, setTab] = useState(0);
  const membershipStatuses = [
    'Active',
    'Canceled',
    'Pending',
    'Deactivated',
    'Expired',
    'Paused',
    '',
  ];
  const colsTitles = [
    'Primary Merchant Plans',
    'Status',
    'Secondary Merchant Customer Group Id',
  ];

  const rowKeys = ['name', 'status', 'partnerCustomerGroupId'];

  const discountColsTitles = [
    'Primary Merchant Plans',
    'Discount',
    'Secondary Merchant Discount Id',
  ];

  const discountRowKeys = [
    'membershipPlan.name',
    'discount',
    'partnerDiscountId',
  ];
  const membershipColsTitles = [
    'Member #',
    'First Name',
    'Last Name',
    'Plan',
    'Email',
    'Phone',
    'Status',
    'Partner Customer Id',
  ];

  const membershipRowKeys = [
    'membership.code',
    'membership.firstName',
    'membership.lastName',
    'membership.plan.name',
    'membership.email',
    'membership.phone',
    'membership.status',
    'partnerCustomerId',
  ];
  const [params, setParams] = useState({ page: 1, limit: 10 });

  const getSecondaryMembershipPlans = async () => {
    if (franchiseBusiness && franchiseBusiness.franchise?.business?.id) {
      const res = await dispatch(
        franchises.actions.getSecondaryMembershipPlans({
          id: franchiseBusiness.id,
          businessId: franchiseBusiness.franchise?.business?.id,
        })
      );
      if (res.payload) {
        setMembershipPlans(res.payload);
      }
    }
  };

  const getSecondaryMembershipPlanDiscounts = async () => {
    if (franchiseBusiness && franchiseBusiness.franchise?.business?.id) {
      const res = await dispatch(
        franchises.actions.getSecondaryMembershipPlanDiscounts({
          id: franchiseBusiness.id,
          businessId: franchiseBusiness.franchise?.business?.id,
        })
      );
      if (res.payload) {
        setMembershipDiscounts(res.payload);
      }
    }
  };
  const getSecondaryMemberships = async (p) => {
    if (franchiseBusiness && franchiseBusiness.franchise?.business?.id) {
      setMembershipLoading(true);
      const res = await dispatch(
        franchises.actions.getSecondaryMemberships({
          ...(p || params),
          id: franchiseBusiness.id,
          businessId: franchiseBusiness.franchise?.business?.id,
        })
      );
      if (res.payload) {
        setMemberships(res.payload);
      }
      setMembershipLoading(false);
      return res.payload;
    }
    return membershipLists;
  };

  const rotatingStyle = {
    animation: 'spin 1s linear infinite',
  };

  const setMembershipPlanPartnerCustomerGroupId = (row, val) => {
    setMembershipPlans((plans) =>
      plans.map((plan) =>
        plan.membershipPlanId === row.membershipPlanId
          ? {
              ...plan,
              partnerCustomerGroupId: val,
            }
          : plan
      )
    );
  };
  const setMembershipPlanPartnerDiscountId = (row, val) => {
    setMembershipDiscounts((plans) =>
      plans.map((plan) =>
        plan.membershipPlanDiscountId === row.membershipPlanDiscountId
          ? {
              ...plan,
              partnerDiscountId: val,
            }
          : plan
      )
    );
  };
  const setMembershipCustomerId = (row, val) => {
    setMemberships((memberships) => {
      if (!Array.isArray(memberships?.data)) return memberships; // Exit early if data is not an array

      return {
        ...memberships,
        data: memberships.data.map((m) =>
          row.membershipId && m.membershipId === row.membershipId
            ? { ...m, partnerCustomerId: val }
            : m
        ),
      };
    });
  };

  const syncMembershipPlan = async (row) => {
    if (row.partnerCustomerGroupId === 'loading' || !franchiseBusiness) return;
    setMembershipPlanPartnerCustomerGroupId(row, 'loading');
    const res = await dispatch(
      franchises.actions.syncMembershipPlan({
        id: franchiseBusiness.id,
        businessId: franchiseBusiness.business?.id,
        membershipPlanId: row.membershipPlanId,
      })
    );
    if (res.payload) {
      setMembershipPlanPartnerCustomerGroupId(
        row,
        res.payload.partnerCustomerGroupId
      );
    } else if (res.error) {
      setMembershipPlanPartnerCustomerGroupId(row, null);
      openAlert({
        message: res.error?.message || 'Failed to sync',
        severity: 'error',
      });
    }
  };

  const syncMembershipPlanDiscount = async (row) => {
    if (row.partnerDiscountId === 'loading' || !franchiseBusiness) return;
    setMembershipPlanPartnerDiscountId(row, 'loading');
    const res = await dispatch(
      franchises.actions.syncMembershipPlanDiscount({
        id: franchiseBusiness.id,
        businessId: franchiseBusiness.business?.id,
        membershipPlanId: row.membershipPlan?.id,
        membershipPlanDiscountId: row.membershipPlanDiscountId,
      })
    );
    if (res.payload) {
      setMembershipPlanPartnerDiscountId(row, res.payload.partnerDiscountId);
    } else if (res.error) {
      setMembershipPlanPartnerDiscountId(row, null);
      openAlert({
        message: res.error?.message || 'Failed to sync',
        severity: 'error',
      });
    }
  };

  const syncMembership = async (row) => {
    if (row.partnerCustomerId === 'loading' || !franchiseBusiness) return;
    setMembershipCustomerId(row, 'loading');
    const res = await dispatch(
      franchises.actions.syncMembership({
        id: franchiseBusiness.id,
        businessId: franchiseBusiness.business?.id,
        membershipId: row.membership?.id || row.membershipId,
      })
    );
    if (res.error) {
      setMembershipCustomerId(row, null);
      openAlert({
        message: res.error?.message || 'Failed to sync',
        severity: 'error',
      });
    } else if (res.payload) {
      setMembershipCustomerId(row, res.payload?.partnerCustomerId);
    }
  };

  const pagination = {
    total: membershipLists?.total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: async (event, page) => {
      const updatedParams = { ...params, page: page + 1 };
      setParams(updatedParams);
      await getSecondaryMemberships(updatedParams);
    },

    handleChangeRowsPerPage: async (event) => {
      const newParams = {
        ...params,
        limit: event.target.value,
        page: 1,
      };
      await setParams(newParams);
      await getSecondaryMemberships(newParams);
    },
  };

  useEffect(() => {
    getSecondaryMemberships();
  }, []);
  const syncAllMembershipPlans = async () => {
    return membershipPlans.reduce((promise, row) => {
      return promise.then(() => syncMembershipPlan(row));
    }, Promise.resolve());
  };

  const syncAllMembershipPlanDiscounts = async () => {
    return membershipDiscounts.reduce((promise, row) => {
      return promise.then(() => syncMembershipPlanDiscount(row));
    }, Promise.resolve());
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const syncAllMemberships = async () => {
    syncingRef.current = true;
    const totalPages = Math.ceil((membershipLists?.total || 0) / params.limit);

    const fetchAndSyncPage = async (currentPage) => {
      await setParams((prev) => ({ ...prev, page: currentPage }));
      await sleep(1000);
      const updatedMembershipLists = await getSecondaryMemberships({
        ...params,
        page: currentPage,
      });
      await updatedMembershipLists?.data.reduce((promise, row) => {
        return promise.then(async () => {
          if (franchiseBusiness?.id && syncingRef.current) {
            await syncMembership(row);
          }
        });
      }, Promise.resolve());

      if (
        currentPage < totalPages &&
        franchiseBusiness?.id &&
        syncingRef.current
      ) {
        await fetchAndSyncPage(currentPage + 1);
      } else {
        syncingRef.current = false;
      }
    };
    await fetchAndSyncPage(params.page);
  };

  const planMods = {
    partnerCustomerGroupId: (val, row) =>
      val && val !== 'loading' ? (
        val
      ) : (
        <Sync
          style={{
            cursor: 'pointer',
            ...(row.partnerCustomerGroupId === 'loading' ? rotatingStyle : {}),
          }}
          onClick={() => syncMembershipPlan(row)}
        />
      ),
  };

  const discountMods = {
    partnerDiscountId: (val, row) =>
      val && val !== 'loading' ? (
        val
      ) : (
        <Sync
          style={{
            cursor: 'pointer',
            ...(row.partnerDiscountId === 'loading' ? rotatingStyle : {}),
          }}
          onClick={() => syncMembershipPlanDiscount(row)}
        />
      ),
  };

  const membershipMods = {
    partnerCustomerId: (val, row) =>
      val && val !== 'loading' ? (
        val
      ) : (
        <Sync
          style={{
            cursor: 'pointer',
            ...(row.partnerCustomerId === 'loading' ? rotatingStyle : {}),
          }}
          onClick={() => syncMembership(row)}
        />
      ),
    'membership.status': (val) => membershipStatuses[val] || 'Deactivated',
  };

  const onStart = async () => {
    await getSecondaryMembershipPlans();
    await getSecondaryMembershipPlanDiscounts();
    await getSecondaryMemberships();
    if (franchiseBusiness?.autoSync) {
      await syncAllMembershipPlans();
      await syncAllMembershipPlanDiscounts();
    }
  };

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} md={4}>
          <Button
            onClick={() => {
              if (!syncingRef.current) {
                syncAllMemberships();
              } else {
                syncingRef.current = false;
              }
            }}
            color="primary"
            variant="contained"
          >
            Sync all members
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  useEffect(() => {
    onStart();
  }, [franchiseBusiness]);

  const body = (
    <Box py={2}>
      <Typography>
        Primary Merchant Name:{' '}
        <b>{franchiseBusiness?.franchise?.business?.name}</b>
      </Typography>
      <Typography>
        Secondary Merchant Name: <b>{franchiseBusiness?.business?.name}</b>
      </Typography>
      <Box mt={3} />

      <Tabs
        value={formTab}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
        TabIndicatorProps={{ style: { background: '#0969da' } }}
        aria-label="Select settings tab"
      >
        <StyledTab
          disableRipple
          id="primary"
          aria-controls="values"
          label="Sync Plans"
        />
        <StyledTab
          disableRipple
          id="secondary"
          aria-controls="values"
          label="Sync Discounts"
        />
        <StyledTab
          disableRipple
          id="secondary"
          aria-controls="values"
          label="Sync Members"
        />
      </Tabs>
      <Box mb={3}>
        <Divider />
      </Box>
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0} style={{ padding: 0 }}>
            {formTab === 0 ? (
              <>
                <Button
                  onClick={syncAllMembershipPlans}
                  color="primary"
                  variant="contained"
                >
                  Sync all plans
                </Button>

                <TransactionTable
                  cols={colsTitles}
                  rowKeys={rowKeys}
                  data={membershipPlans || []}
                  mods={planMods}
                />
              </>
            ) : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 1 ? (
              <>
                <Button
                  onClick={syncAllMembershipPlanDiscounts}
                  color="primary"
                  variant="contained"
                >
                  Sync all discounts
                </Button>

                <TransactionTable
                  cols={discountColsTitles}
                  rowKeys={discountRowKeys}
                  data={membershipDiscounts || []}
                  mods={discountMods}
                />
              </>
            ) : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={2} style={{ padding: 0 }}>
            {formTab === 2 ? (
              <>
                <TransactionTable
                  cols={membershipColsTitles}
                  rowKeys={membershipRowKeys}
                  data={membershipLists?.data || []}
                  mods={membershipMods}
                  pagination={pagination}
                  searchBox={searchBox}
                  isLoading={membershipLoading}
                />
              </>
            ) : null}
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </Box>
  );

  return (
    <Dialog
      open={!!franchiseBusiness?.id}
      onClose={() => {
        syncingRef.current = false;
        closeModal();
      }}
      fullWidth
      maxWidth="lg"
      disableEnforceFocus
    >
      {/* <DialogTitle>Primary Merchant</DialogTitle> */}
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

MembershipFranchiseModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  franchiseBusiness: PropTypes.object.isRequired,
};

export default MembershipFranchiseModal;
