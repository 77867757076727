/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import SEO from '../../seo';
import { stats, alert } from '../../../state';
import { TransactionTable, DownloadCSV } from '../../custom';

const colsTitles = [
  'Date',
  'Merchant',
  'Discount',
  'Card Value',
  'CC Fee?',
  'Customer Charged',
  'Stripe Fee',
  'Stripe To Pintuna',
  'Amount to BHN',
  'BHN Fee',
  'Amount to Nonprofit',
  'Pintuna Profit/Loss',
];

const rowKeys = [
  'transactionDate',
  'merchantName',
  'discountPercent',
  'cardValue',
  'isFeeCovered',
  'chargedAmount',
  'stripeFee',
  'pintunaAmount',
  'partnerAmount',
  'partnerServiceFee',
  'nonprofitAmount',
  'pintunaMargin',
];

const mods = {
  transactionDate: (val) =>
    moment.utc(val).local().format('MMM DD YYYY, HH:mm'),
  discountPercent: (val) => `${(val * 100).toFixed(2)}%`,
  cardValue: (val) => `$${val.toFixed(2)}`,
  isFeeCovered: (val) =>
    val === true ? `Y` : <Typography color="error">N</Typography>,
  chargedAmount: (val) => `$${val.toFixed(2)}`,
  stripeFee: (val) => `$${val.toFixed(2)}`,
  pintunaAmount: (val) => `$${val.toFixed(2)}`,
  partnerServiceFee: (val) => `$${val.toFixed(2)}`,
  partnerAmount: (val) => `$${val.toFixed(2)}`,
  nonprofitAmount: (val) => `$${val.toFixed(2)}`,
  pintunaMargin: (val) =>
    val > 0 ? (
      <Typography style={{ color: '#76a220' }}>
        {`$${val.toFixed(2)}`}
      </Typography>
    ) : (
      <Typography color="error">{`$${val.toFixed(2)}`}</Typography>
    ),
};

const TransactionsSection = ({
  transactions,
  isLoading,
  getTransactions,
  nextTransactions,
  prevTransactions,
  noMoreNextTransactions,
  noMorePrevTransactions,
  csv,
  downloadCSV,
  openAlert,
}) => {
  const [limit, setLimit] = React.useState('11');

  React.useEffect(() => {
    getTransactions(limit);
  }, []);

  const refLink = React.useRef();

  const handleDownload = () => {
    downloadCSV()
      .then(() => {
        refLink.current.link.click();
      })
      .then(() =>
        openAlert({
          message: 'CSV list Downloaded! GREAT SUCCESS!',
          severity: 'success',
        })
      );
  };

  const handlePrev = () => {
    const id = getOr(null, 'id', transactions[0]);
    prevTransactions({ id, limit });
  };

  const handleNext = () => {
    const id = getOr(null, 'id', transactions[transactions.length - 1]);
    nextTransactions({ id, limit });
  };

  const handleQtyClick = (e, val) => {
    if (val !== null) {
      setLimit(val);
      getTransactions(val);
    }
    e.preventDefault();
  };

  return (
    <>
      <SEO title="Detailed Transactions" />
      <Grid container justifyContent="space-between">
        <Grid item>
          <DownloadCSV
            handleDownload={handleDownload}
            refLink={refLink}
            data={csv}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <ToggleButtonGroup
                value={limit}
                size="small"
                exclusive
                onChange={handleQtyClick}
                aria-label="Transactions to show"
              >
                <ToggleButton value="11" aria-label="Show 10 transactions">
                  10
                </ToggleButton>
                <ToggleButton value="51" aria-label="Show 50 transactions">
                  50
                </ToggleButton>
                <ToggleButton value="101" aria-label="Show 100 transactions">
                  100
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <Button
                disabled={noMorePrevTransactions}
                onClick={handlePrev}
                variant="contained"
                color="primary"
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={noMoreNextTransactions}
                onClick={handleNext}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={transactions}
          mods={mods}
          isLoading={isLoading}
          noDataMsg="Hmm... Looks like there is no data."
        />
      </Box>
    </>
  );
};

TransactionsSection.defaultProps = {
  transactions: [],
  csv: [],
};

TransactionsSection.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.any),
  csv: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
  noMoreNextTransactions: PropTypes.bool.isRequired,
  noMorePrevTransactions: PropTypes.bool.isRequired,
  getTransactions: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  nextTransactions: PropTypes.func.isRequired,
  prevTransactions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  transactions: stats.selectors.selectTransactions(state),
  isLoading: stats.selectors.selectTransactionsIsLoading(state),
  noMoreNextTransactions: stats.selectors.selectNoMoreNextTransactions(state),
  noMorePrevTransactions: stats.selectors.selectNoMorePrevTransactions(state),
  csv: stats.selectors.selectCSV(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTransactions: (limit) => dispatch(stats.actions.getTransactions(limit)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
  downloadCSV: (limit) => dispatch(stats.actions.downloadCSV(limit)),
  nextTransactions: (payload) =>
    dispatch(stats.actions.nextTransactions(payload)),
  prevTransactions: (payload) =>
    dispatch(stats.actions.prevTransactions(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsSection);
