/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, Grid, TextField } from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker';
import { DateRange } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { TransactionTable } from '../../custom';

const useStyles = makeStyles({
  headerLabel: {
    textTransform: 'capitalize',
  },
  tableContainer: {
    marginTop: 30,
  },
  datePicker: {
    zIndex: 100,
    position: 'absolute',
  },
});

const SummaryTable = ({ data, label, refreshData }) => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({
    startDate,
    endDate: date,
  });
  const toggle = () => setOpen(!open);

  const resetData = (range) => {
    toggle();
    refreshData(range);
    setDateRange(range);
  };

  const colsTitles = ['Currency', 'Cards Sold', 'Total Value', 'Average Value'];
  const rowKeys = ['currency', 'cardsSold', 'totalValue', 'averageValue'];
  const mods = {
    totalValue: (val, row) =>
      `${getSymbolFromCurrency(row.currency) || ''}${val.toFixed(2)}`,
    averageValue: (val, row) =>
      `${getSymbolFromCurrency(row.currency) || ''}${val.toFixed(2)}`,
  };

  return (
    <Grid container>
      <Grid xs="12">
        <Grid sm={12}>
          <h1>{label}</h1>
        </Grid>
        <Grid container direction="row" sm={12}>
          <TextField
            label="Start Date"
            value={dateRange.startDate.toDateString()}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            value={dateRange.endDate.toDateString()}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DateRange onClick={() => toggle()} />
          <DateRangePicker
            wrapperClassName={classes.datePicker}
            open={open}
            toggle={toggle}
            onChange={(range) => resetData(range)}
          />
        </Grid>
      </Grid>
      <Grid xs="12" lg="10" xl="8">
        <Box>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data || []}
            mods={mods}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>
    </Grid>
  );
};

SummaryTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default SummaryTable;
