import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import SyncSquareMembers from './SyncSquareMembers';

const GiftCardConfigs = () => {
  return (
    <Router basepath={withPrefix('dashboard/syncMembers')}>
      <SyncSquareMembers path="/square" />
    </Router>
  );
};

export default GiftCardConfigs;
