import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Divider, Box, Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { auth } from '../../../state';
import SEO from '../../seo';
import InfoSection from './InfoSection';
import ChartSection from './ChartSection';
import FundsRaisedSection from './FundsRaisedSection';
import TransactionsSection from './TransactionsSection';

const Dashboard = () => {
  const defaults = {
    chart: false,
    raised: false,
    transactions: false,
  };
  const [screen, setScreen] = React.useState(defaults);

  React.useEffect(() => {
    setScreen({ ...defaults, chart: true });
  }, []);

  const handleScreen = (newScreen) => {
    setScreen({ ...defaults, ...newScreen });
  };

  return (
    <>
      <SEO title="Dashboard" />
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          {!screen.chart && (
            <Grid item>
              <IconButton
                aria-label="Back"
                onClick={() => handleScreen({ chart: true })}
                style={{ padding: 6 }}
              >
                <ArrowBack fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Typography variant="h5">
              {screen.chart && 'Dashboard'}
              {screen.transactions && 'Detailed Transactions'}
              {screen.raised && 'Total Funds Raised'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <InfoSection handleScreen={handleScreen} />
      </Box>
      <Box py={2}>
        {screen.chart && <ChartSection />}
        {screen.transactions && <TransactionsSection />}
        {screen.raised && <FundsRaisedSection />}
      </Box>
    </>
  );
  // console.log('user', user);
  // return (
  //   <>
  //     <SEO title="Dashboard" />
  //     <Box pb={2}>
  //       <Typography variant="h5">Dashboard</Typography>
  //     </Box>
  //     <Divider />
  //     <Box py={2}>
  //       <InfoSection />
  //     </Box>
  //     <Box py={2}>
  //       <ChartSection />
  //     </Box>
  //   </>
  // );
};
Dashboard.defaultProps = {};

Dashboard.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: auth.selectors.selectUser(state),
});
export default connect(mapStateToProps)(Dashboard);
