import React from 'react';
import {
  Grid,
  CircularProgress,
  Button,
  MenuItem,
  Box,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CustomInputField from '../../custom/CustomInputField';
import { AddressValidation } from './validation';

import listOfStates from '../../../../misc/listOfStates.json';

const AddressSection = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12} md={4}>
        <Formik
          enableReinitialize
          validationSchema={AddressValidation}
          initialValues={{
            line1: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'United States',
          }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Address"
                    name="line1"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        capitalize
                        fullWidth
                        label="City"
                        name="city"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        label="State"
                        select
                        name="state"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      >
                        {Object.keys(listOfStates).map((state) => (
                          <MenuItem
                            key={state}
                            value={state}
                            onClick={() => setFieldValue('state', state)}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        label="Zip code"
                        name="postal_code"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            maxLength: '5',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box pt={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      disabled
                      label="Country"
                      name="country"
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  size="large"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Save Address'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddressSection;
