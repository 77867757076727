/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { card } from '../../../../state';
import franchises from '../../../../state/franchises';

const SyncFranchiseGiftCardModal = ({ isOpen, closeModal, type }) => {
  const dispatch = useDispatch();
  // const openAlert = (data) => dispatch(alert.actions.open(data));

  const franchiseBusiness = useSelector(
    franchises.selectors.selectFranchiseBusiness
  );

  const [params, setParams] = useState({
    page: 1,
    pageSize: 50,
    businessId: franchiseBusiness.business?.id,
  });

  const getGiftCardsList = () => {
    dispatch(card.actions.getSquareGiftCardsList(params));
  };

  useEffect(() => {
    if (!franchiseBusiness.id) {
      closeModal();
    } else {
      getGiftCardsList();
    }
  }, [franchiseBusiness]);
  const colsTitles = ['Second Merchant Id', 'Create Date'];

  const rowKeys = ['code', 'createdAt'];
  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  headers.push({ label: 'Merchant Name', key: 'businessName' });
  // const getBusiness = async (value) => {
  //   if (!value) return;
  //   const res = await dispatch(business.actions.getBusiness(value));
  //   if (res.error) {
  //     openAlert({
  //       message: res.error?.message || 'Merchant is not found',
  //       severity: 'error',
  //     });
  //   }
  // };

  const body = (
    <Box py={2}>
      <Grid container spacing={2} style={{ flexGrow: 1 }}>
        <Grid item md={10} xs={12}>
          {JSON.stringify(franchiseBusiness)}
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      {/* <DialogTitle>Primary Merchant</DialogTitle> */}
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

SyncFranchiseGiftCardModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SyncFranchiseGiftCardModal;
