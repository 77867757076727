/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Typography, Box, makeStyles, Button, Grid } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'gatsby-theme-material-ui';
import { enterprises } from '../../../state';
import SEO from '../../seo';
import CreateCompany from './CreateCompany';
import EditCompanyProfile from './EditCompanyProfile';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {},
});

const CompanyProfile = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [itemSelected, setItemSelected] = React.useState({});
  const dispatch = useDispatch();
  const { data: enterprisesList, total } = useSelector(
    enterprises.selectors.selectEnterprisesData
  );

  const setEnterprise = (payload) =>
    dispatch(enterprises.actions.setCurrentEnterprise(payload));

  const columns = [
    { id: 'name', label: 'Company Name' },
    { id: 'address', label: 'Address' },
    { id: 'city', label: 'City' },
    { id: 'state', label: 'State' },
    { id: 'country', label: 'Country' },
    { id: 'website', label: 'Website' },
    {
      id: 'column1',
      label: 'Column 1',
      format: (row, value, handleChange) => (
        <Typography
          variant="body1"
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => handleChange(row, 'edit', value)}
        >
          Edit profile
        </Typography>
      ),
    },
    {
      id: 'column2',
      label: 'Column 2',
      format: (row) => (
        <Link
          to={`/dashboard/enterprises/members/${row.id}`}
          underline="none"
          onClick={() => setEnterprise(row)}
        >
          <Typography
            variant="body1"
            style={{ cursor: 'pointer', color: 'blue' }}
          >
            Users
          </Typography>
        </Link>
      ),
    },
    {
      id: 'column3',
      label: 'Column 3',
      format: (row) => (
        <Link
          to={`/dashboard/enterprises/merchants/${row.id}`}
          onClick={() => setEnterprise(row)}
          underline="none"
        >
          <Typography
            variant="body1"
            style={{ cursor: 'pointer', color: 'blue' }}
          >
            Merchants
          </Typography>
        </Link>
      ),
    },
  ];
  const [headers] = React.useState(columns);

  const handleGetList = async (params) => {
    await dispatch(enterprises.actions.getEnterprisesList(params));
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  // On First Load
  React.useEffect(() => {
    handleGetList({
      page: page + 1,
      limit: rowsPerPage,
    });
  }, []);

  const openModal = () => {
    setItemSelected({});
    setIsOpen(true);
  };

  const closeModal = async (isRefresh) => {
    setIsOpen(false);
    setIsEditing(false);
    if (isRefresh) {
      handleGetList({
        page: page + 1,
        limit: rowsPerPage,
      });
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    handleGetList({
      page: newPage + 1,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    const pageLimit = event.target.value;
    setRowsPerPage(pageLimit);
    setPage(0);
    handleGetList({
      page: page + 1,
      limit: pageLimit,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = async (row, type, value) => {
    const item = { ...row };
    const enterpriseMemberOwner = item.enterpriseMembers.find(
      (i) => i.role === 4
    );
    if (enterpriseMemberOwner && enterpriseMemberOwner.account) {
      item.firstName = enterpriseMemberOwner.account.firstName;
      item.lastName = enterpriseMemberOwner.account.lastName;
      item.email = enterpriseMemberOwner.account.email;
      item.accountId = enterpriseMemberOwner.account.id;
    }

    setItemSelected(item);
    setIsEditing(true);
  };

  const handleCreate = () => {
    openModal();
  };

  // Get Merchant Data.
  return (
    <>
      <SEO title="Enterprise Customers - Company Profile" />
      <Box pb={2}>
        <Typography variant="h5">
          Enterprise Customers - Company Profile
        </Typography>
      </Box>

      <CreateCompany
        isOpen={isOpen}
        closeModal={closeModal}
        itemSelected={itemSelected}
      />
      <EditCompanyProfile
        isOpen={isEditing}
        closeModal={closeModal}
        itemSelected={itemSelected}
      />
      <Grid container justifyContent="flex-start">
        <Grid item xs={12} md={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleCreate}
            size="small"
          >
            ADD ENTERPRISE
          </Button>
        </Grid>
        <Grid item md={8} xs={12} lg={8}>
          <Box py={2} style={{ width: '100%' }}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headers.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enterprisesList.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {headers.map((header) => {
                            const value = row[header.id];
                            return (
                              <TableCell key={header.id} align={header.align}>
                                {header.format
                                  ? header.format(row, value, handleChange)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CompanyProfile.propTypes = {
  // data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = () => ({
  // data: enterprises.selectors.selectEnterprisesData(state),
});

export default connect(mapStateToProps)(CompanyProfile);
