/* eslint-disable react/prop-types,no-await-in-loop,no-param-reassign */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import CSVReader from 'react-csv-reader';
import {
  Check,
  // CheckBox,
  // CheckBoxOutlineBlank,
  Close,
} from '@material-ui/icons';
import { alert, business, card } from '../../../../state';
import { TransactionTable } from '../../../custom';
import CustomInputField from '../../../custom/CustomInputField';
import { PartnerEnum } from '../../../../utils/constants';

const colsTitles = [
  '#',
  'Merchant Id',
  'Merchant Name',
  'Gift Card #',
  'Gift Card Id',
  'Current Balance',
  'Sent',
];

const rowKeys = [
  'index',
  'businessId',
  'merchantName',
  'code',
  'referenceId',
  'amount',
  'sent',
];

const ImportSquareGiftCards = () => {
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const data = useSelector(card.selectors.selectPhysicalGiftCardList);
  const [currency, setCurrency] = useState('USD');
  const [businessData, setBusinessData] = React.useState({});
  const getBusiness = async (event) => {
    const { value } = event.target;
    if (!value) {
      setBusinessData({});
      return;
    }
    const res = await dispatch(business.actions.getBusiness(value));
    if (res.error) {
      openAlert({
        message: res.error?.message || 'Merchant is not found',
        severity: 'error',
      });
      setBusinessData({});
    } else {
      setBusinessData(res.payload || {});
      setCurrency(res.payload?.currency || 'USD');
    }
  };
  // const currency = businessData.currency || 'USD';

  const currencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    }).format(value);
  const refForm = useRef();
  const refLink = useRef();
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const [currentIndex, setCurrentIndex] = useState(-1);

  const getPhysicalCard = async (values) => {
    await setIsLoading(true);
    const updatedArray = data;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < updatedArray.length; i++) {
      const row = updatedArray[i];
      updatedArray[i].sent = 'loading';
      setData(updatedArray);
      setCurrentIndex(i);
      try {
        let resp = null;
        switch (businessData.partner) {
          case PartnerEnum.Shopify:
            resp = await dispatch(
              card.actions.issueShopifyGiftCard({
                ...row,
                ...values,
                primaryBusinessId: row.businessId,
              })
            );
            break;
          default:
            resp = await dispatch(
              card.actions.issueSquareGiftCard({
                ...row,
                ...values,
              })
            );
            break;
        }
        if (resp.payload) {
          updatedArray[i].sent = true;
          setData(updatedArray);
          openAlert({
            message: `Gift Card # ${updatedArray[i].code} is created successfully`,
            severity: 'success',
          });
        } else if (resp.error) {
          updatedArray[i].sent = 'failed';
          setData(updatedArray);
          openAlert({
            message: `Failed to create Gift Card # ${updatedArray[i].code}!\nError message: ${resp.error?.message}`,
            severity: 'error',
          });
          // break;
        }
      } catch (error) {
        console.log(error);
        break;
      }
      if (i === updatedArray.length) {
        openAlert({
          message: `All Gift Cards are created successfully`,
          severity: 'success',
        });
      }
    }
    await setIsLoading(false);
  };

  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => rowKeys[colsTitles.indexOf(header)],
  };

  const downloadCSV = () => {
    fetch('../../../Sample File - Import for Secondary Merchants.csv').then(
      (response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Sample File - Import for Secondary Merchants.csv';
          alink.click();
        });
      }
    );
  };

  const handleCsvUpload = (_data) => {
    // _data.forEach((row, i) => {
    //   _data[i].businessId = businessData.id;
    //   _data[i].businessName = businessData.name;
    // });
    setData(_data);
  };

  return (
    <>
      <Box py={2}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={10} xs={12}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: 'a6a900a9-5661-4721-a035-59469bd3027d',
                merchantName: '',
                posSystem: '',
              }}
              onSubmit={getPhysicalCard}
            >
              {(props) => {
                const { handleSubmit } = props;
                refForm.current = props;
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                          onBlur={getBusiness}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          placeholder="Merchant Name"
                          name="merchantName"
                          value={businessData.name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          placeholder="Pos System"
                          name="posSystem"
                          variant="outlined"
                          value={businessData.partner}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg="auto">
                          <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            disabled={!businessData.id}
                          >
                            Import from CSV
                            <CSVReader
                              inputId="CSVReader"
                              inputStyle={{ display: 'none' }}
                              onFileLoaded={handleCsvUpload}
                              parserOptions={parseOptions}
                            />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="a"
                            color="textSecondary"
                            onClick={downloadCSV}
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              display: 'flex',
                              verticalAlign: 'center',
                            }}
                          >
                            Sample CSV
                          </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            size="large"
                            disabled={!data.length}
                          >
                            Create Gift Cards
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid item md={12} xs={12}>
          <CSVLink
            data={data}
            filename="physicalGiftCards.csv"
            className="hidden"
            ref={refLink}
            target="_blank"
            headers={headers}
          />
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            mods={{
              sent: (val) => {
                switch (val) {
                  case true:
                    return (
                      <Check
                        color="secondary"
                        size={14}
                        style={{ color: 'green' }}
                      />
                    );
                  case 'loading':
                    return <CircularProgress color="primary" size={14} />;
                  case 'failed':
                    return <Close color="error" size={14} />;
                  default:
                    return '';
                }
              },
              amount: (val) => currencyFormat((val || 0).toFixed(2)),
            }}
            pagination={{
              total: data.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <Typography variant="h6">
                    {'Total Amount: '}
                    {currencyFormat(
                      data.length
                        ? data.reduce((a, b) => {
                            return (
                              Number(a) +
                              Number(
                                (b.amount ? b.amount.toString() : '0').match(
                                  /(\d+(\.\d+)?)/
                                )[1]
                              )
                            );
                          }, 0)
                        : 0
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
            // isLoading={isLoading}
            key={currentIndex}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
    </>
  );
};

export default ImportSquareGiftCards;
