import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  AttachMoney,
  CardGiftcard,
  Business,
  WorkOutline,
} from '@material-ui/icons';
import { InfoCard } from '../../custom';
import { stats } from '../../../state';

const InfoSection = ({ data, isLoading, handleScreen }) => {
  const {
    merchantsCount,
    orgsCount,
    totalAmountCharged,
    totalCardsSold,
    totalFundsRaised = 0,
    totalValueSold = 0,
  } = data;

  const ccFees = (totalAmountCharged - totalValueSold).toFixed(2);

  return (
    <>
      <Grid container spacing={4}>
        <Grid container item xs={12} md={4}>
          <InfoCard
            label="TOTAL GIFT CARDS SOLD"
            icon={<CardGiftcard fontSize="large" />}
            isLoading={isLoading}
            clickable
            onClick={() => handleScreen({ transactions: true })}
          >
            {totalCardsSold}
          </InfoCard>
        </Grid>
        <Grid container item xs={12} md={4}>
          <InfoCard
            label="TOTAL AMOUNT (SOLD GIFT CARDS)"
            icon={<AttachMoney fontSize="large" />}
            isLoading={isLoading}
          >
            {totalValueSold.toFixed(2)}
          </InfoCard>
        </Grid>
        <Grid container item xs={12} md={4}>
          <InfoCard
            label="TOTAL FUNDS RAISED"
            icon={<AttachMoney fontSize="large" />}
            isLoading={isLoading}
            disabled={isLoading}
            clickable
            onClick={() => handleScreen({ raised: true })}
          >
            {totalFundsRaised.toFixed(2)}
          </InfoCard>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid container item xs={12} md={4}>
          <InfoCard
            label="NUMBER OF NONPROFIT ORGANIZATIONS"
            icon={<WorkOutline fontSize="large" />}
            isLoading={isLoading}
          >
            {orgsCount}
          </InfoCard>
        </Grid>
        <Grid container item xs={12} md={4}>
          <InfoCard
            label="NUMBER OF MERCHANTS"
            icon={<Business fontSize="large" />}
            isLoading={isLoading}
          >
            {merchantsCount}
          </InfoCard>
        </Grid>
        <Grid container item xs={12} md={4}>
          <InfoCard
            label="CREDIT CARD FEES"
            icon={<CardGiftcard fontSize="large" />}
            isLoading={isLoading}
          >
            {ccFees}
            {/* <Box>
              <Typography component="span" color="textSecondary">
                Web cards sold:{' '}
              </Typography>
              <Typography component="span">coming soon </Typography>
            </Box>
            <Box>
              <Typography component="span" color="textSecondary">
                Mob cards sold:{' '}
              </Typography>
              <Typography component="span">coming soon </Typography>
            </Box> */}
          </InfoCard>
        </Grid>
      </Grid>
    </>
  );
};

InfoSection.propTypes = {
  data: PropTypes.shape({
    merchantsCount: PropTypes.number,
    orgsCount: PropTypes.number,
    totalCardsSold: PropTypes.number,
    totalFundsRaised: PropTypes.number,
    totalValueSold: PropTypes.number,
    totalAmountCharged: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleScreen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: stats.selectors.selectStats(state),
  isLoading: stats.selectors.selectIsLoading(state),
});

export default connect(mapStateToProps)(InfoSection);
