import React from 'react';

import { Box, Divider, Tabs, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TabContext, TabPanel } from '@material-ui/lab';
import SEO from '../../../seo';
import StyledTab from '../../../custom/StyledTab';
import { LookupExistingCard } from './LookupExistingCard';
import { GenerateNewCard } from './GenerateNewCard';
import ImportGiftCards from './ImportGiftCards';

export const PhysicalGiftCard = () => {
  const [formTab, setTab] = React.useState(0);
  return (
    <>
      <SEO title="Physical Gift Cards" />
      <Box pb={2}>
        <Typography variant="h5">Merchants - Physical Card #s</Typography>
      </Box>
      <Divider />
      <Box>
        <Tabs
          value={formTab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          TabIndicatorProps={{ style: { background: '#0969da' } }}
        >
          <StyledTab
            disableRipple
            id="generate"
            aria-controls="values"
            label="Generate New Card #s"
          />
          <StyledTab
            disableRipple
            id="lookup"
            aria-controls="values"
            label="Lookup Existing Card #s"
          />
          <StyledTab
            disableRipple
            id="lookup"
            aria-controls="values"
            label="Import Gift Cards"
          />
        </Tabs>
      </Box>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0} style={{ padding: 0 }}>
            {formTab === 0 ? <GenerateNewCard /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 1 ? <LookupExistingCard /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 2 ? <ImportGiftCards /> : null}
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </>
  );
};

export default { PhysicalGiftCard };
