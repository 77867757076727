/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import SEO from '../../../seo';
import { memberships } from '../../../../state';
import { TransactionTable } from '../../../custom';
import AddPrimaryFranchiseModal from '../Modal/AddPrimaryFranchiseModal';
import franchises from '../../../../state/franchises';
import ConfirmationDialog from '../../../custom/ConfirmDialog';
import Editing from '../../../../images/editing.png';

const colsTitles = [
  'Group / Franchise Name',
  'Primary Merchant Name',
  'Merchant Id',
  'Pintuna Merchant Id',
  'POS',
  'Enable',
  '',
];

const rowKeys = [
  'name',
  'business.name',
  'business.merchantId',
  'business.id',
  'business.partner',
  'enabled',
  'id',
];

// eslint-disable-next-line react/prop-types
const PrimaryMerchant = ({ type = 0 }) => {
  const [params, setParams] = useState({ limit: 10, page: 1, type });
  const [isOpen, setIsOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [selectGiftCard, setSelectGiftCard] = useState(null);
  const dispatch = useDispatch();
  const { data, total } = useSelector(
    franchises.selectors.selectFranchisesList
  );
  const getFranchiseList = (p) => {
    dispatch(franchises.actions.getFranchisesList(p || params));
  };
  useEffect(() => {
    getFranchiseList();
  }, [params]);

  const isLoading = useSelector(memberships.selectors.selectIsLoading);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const updateEnabled = async (row) => {
    await dispatch(
      franchises.actions.createFranchise({
        businessId: row.business.id,
        name: row.name,
        id: row.id,
        enabled: !row.enabled,
        type: row.type,
      })
    );
    dispatch(franchises.actions.getFranchisesList(params));
  };

  const editGiftCard = (item) => {
    setSelectGiftCard(item);
    setIsOpen(true);
  };

  const mods = {
    enabled: (val, row) =>
      val ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setConfirmation(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateEnabled(row)}
        />
      ),
    id: (_id, row) => (
      <>
        <Tooltip title="Edit" placement="top" arrow>
          <IconButton
            style={{
              padding: 0,
              marginTop: -8,
              marginLeft: 8,
              marginRight: 8,
            }}
            aria-haspopup="true"
            cursor="pointer"
            onClick={() => editGiftCard(row)}
          >
            <img src={Editing} alt="" style={{ width: 20 }} />
          </IconButton>
        </Tooltip>
      </>
    ),
  };

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
    getFranchiseList(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    setParams(p);
    getFranchiseList(p);
  };
  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return (
    <>
      <SEO title="Membership discount" />
      <AddPrimaryFranchiseModal
        isOpen={isOpen}
        type={type}
        onSucceed={getFranchiseList}
        closeModal={() => {
          setIsOpen(false);
          setSelectGiftCard(null);
        }}
        item={selectGiftCard}
      />
      <Grid xs="12" lg="10" xl="8">
        <Box py={2}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data || []}
            mods={mods}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <Button
                    disabled={isLoading}
                    onClick={() => handleOpenModal(true)}
                    variant="contained"
                    color="primary"
                  >
                    Add Primary Merchant
                  </Button>
                </Grid>
              </Grid>
            )}
            isLoading={isLoading}
            pagination={pagination}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>

      <ConfirmationDialog
        open={!!confirmation}
        setOpen={setConfirmation}
        title="Confirmation"
        isConfirmation
        content="Are you sure you want to deactivate the sync process?"
        actionOk={() => {
          updateEnabled(confirmation);
        }}
      />
    </>
  );
};

export default PrimaryMerchant;
