import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, MenuItem, Select } from '@material-ui/core';
import { Business, CardGiftcard } from '@material-ui/icons';
import moment from 'moment';
import { stats } from '../../../state';
import ChartSection from './ChartSection';
import { InfoCard } from '../../custom';

const MerchantsDashboard = () => {
  const dispatch = useDispatch();
  const merchantStats = useSelector(stats.selectors.selectMerchantStats);
  const isLoading = useSelector(stats.selectors.selectIsLoading);

  const [params, setParams] = useState({
    startDate: moment().startOf('y').format('MM/DD/YYYY'),
    endDate: moment().format('MM/DD/YYYY'),
    limit: 10,
    page: 1,
  });

  const [filter, setFilter] = useState('currentYear');
  const dataOptions = [
    { key: 'currentYear', text: 'Current Year' },
    { key: 'lastYear', text: 'Last Year' },
  ];

  useEffect(() => {
    if (filter === 'currentYear') {
      setParams({
        ...params,
        startDate: moment().startOf('year').format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY'),
      });
    }
    if (filter === 'lastYear') {
      setParams({
        ...params,
        startDate: moment().add(-1, 'y').startOf('year').format('MM/DD/YYYY'),
        endDate: moment().add(-1, 'y').endOf('y').format('MM/DD/YYYY'),
      });
    }
  }, [filter]);

  useEffect(() => {
    dispatch(stats.actions.getMerchantStats(params));
  }, [JSON.stringify(params)]);

  return (
    <>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              label="TOTAL SQUARE MERCHANTS"
              icon={<Business fontSize="large" />}
              isLoading={false}
            >
              {merchantStats.totalSquareMerchants}
            </InfoCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              label="TOTAL CLOVER MERCHANTS"
              icon={<CardGiftcard fontSize="large" />}
              isLoading={false}
              onClick={() => {}}
            >
              {merchantStats.totalCloverMerchants}
            </InfoCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              label="TOTAL MERCHANTS"
              icon={<CardGiftcard fontSize="large" />}
              isLoading={false}
              onClick={() => {}}
            >
              {merchantStats.totalMerchants}
            </InfoCard>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Select
              autoWidth
              name="filter"
              variant="outlined"
              value={filter}
              onChange={(event, val) => {
                setFilter(val?.props?.value);
              }}
              style={{ height: '47px' }}
            >
              {dataOptions.map((opt) => (
                <MenuItem value={opt.key} key={opt.key}>
                  {opt.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        {merchantStats.merchantPartnerSummary && (
          <ChartSection
            isLoading={isLoading}
            data={merchantStats.merchantPartnerSummary}
          />
        )}
      </Box>
    </>
  );
};

export default MerchantsDashboard;
