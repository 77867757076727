/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import { Link } from 'gatsby-theme-material-ui';
import suggestDimensions from '../../../../images/suggestedImageDimensions.png';
import { CustomImgCard } from '../../../custom';

const CardSection = ({
  merchantCreatives,
  setGiftCardImage,
  handleSuccess,
  imgIsLoading,
  isLoading,
  field,
  type,
  disabled,
  children,
  showDimensions,
  imagePreview = '',
}) => {
  const { setFieldValue } = useFormikContext();
  const fileInput = React.useRef(null);
  const [previewImg, setPreviewImg] = React.useState(imagePreview);
  const [errors, setErrors] = React.useState('');
  const { name } = merchantCreatives || {};

  useEffect(() => {
    setPreviewImg(imagePreview);
  }, [imagePreview]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const formData = new FormData();
    formData.append('file', file);
    reader.readAsDataURL(file);
    if (file.size > 512000) {
      setErrors(`*File cannot exceed ${file.size}KB`, { size: 500 });
    } else {
      reader.onloadend = async () => {
        const res = await setGiftCardImage(formData);
        if (res && res.error) {
          fileInput.current.value = '';
          setErrors(res.error.message);
          setPreviewImg('');
          setFieldValue(field, merchantCreatives?.[field] || '');
        }
        if (res && res.payload) {
          setErrors('');
          setPreviewImg(reader.result);
          if (handleSuccess) {
            handleSuccess(res.payload[field] || res.payload.imageUrl);
          } else {
            setFieldValue(field, res.payload[field] || res.payload.imageUrl);
          }
        }
      };
    }
  };

  const [zoom, setZoom] = React.useState(null);
  const handleClear = () => {
    fileInput.current.value = '';
    setPreviewImg('');
    setErrors('');
    setFieldValue(
      field,
      merchantCreatives?.[field] || merchantCreatives?.imageUrl || ''
    );
    if (handleSuccess) {
      handleSuccess(merchantCreatives?.[field] || merchantCreatives?.imageUrl);
    }
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      direction={type === 'membership' ? 'column' : 'row'}
      spacing={1}
    >
      <Grid container item xs={12} md={type === 'membership' ? 12 : 6}>
        <CustomImgCard
          isLoading={isLoading || imgIsLoading}
          name={name || 'giftCardImage'}
          width="100%"
          imageUrl={
            (isLoading && '') || previewImg || merchantCreatives?.[field] || ''
          }
          rectangle={false}
        />
        <Box ml={1} width="100%">
          <Typography
            variant="caption"
            color={errors ? 'error' : 'textPrimary'}
          >
            {errors || '*Image must not exceed 500kb'}
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={12} md={type === 'membership' ? 12 : 6}>
        <Box py={1} display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            component="label"
            disabled={imgIsLoading || disabled}
            style={{ minWidth: 190 }}
            // onClick={() => setOpen(true)}
          >
            {type === 'giftCard' && 'Upload Gift Card Image'}
            {type === 'membership' && 'Upload Digital Card'}
            {type === 'storeCredit' && 'Upload Image'}
            {type === 'loyalty' && 'Upload Image'}
            {type === 'ticket' && 'Upload Image'}
            <input
              id="file"
              type="file"
              ref={fileInput}
              hidden
              accept="image/*"
              name="file"
              disabled={imgIsLoading}
              onChange={handleImageUpload}
            />
          </Button>
          <IconButton
            aria-label="clear"
            disabled={imgIsLoading || disabled}
            onClick={handleClear}
            style={{ float: 'left', padding: 3 }}
          >
            {imgIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Close fontSize="small" />
            )}
          </IconButton>
        </Box>
        <Box>{children}</Box>
        {showDimensions && (
          <Link
            underline="hover"
            color="initial"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#0969da',
              fontWeight: 'bold',
            }}
            onClick={() => setZoom(suggestDimensions)}
          >
            Suggested Image Dimensions
          </Link>
        )}
      </Grid>
      <Dialog open={!!zoom} onClose={() => setZoom(null)} maxWidth="lg">
        <DialogContent>
          <img
            src={zoom}
            style={{ minWidth: 300, maxWidth: '100%', maxHeight: '90vh' }}
            alt=""
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

CardSection.propTypes = {
  merchantCreatives: PropTypes.objectOf(PropTypes.any).isRequired,
  imgIsLoading: PropTypes.bool.isRequired,
  setGiftCardImage: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  field: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  showDimensions: PropTypes.bool,
  children: PropTypes.elementType,
  imagePreview: PropTypes.string,
};

CardSection.defaultProps = {
  field: 'imageUrl',
  handleSuccess: null,
  type: 'giftCard',
  disabled: false,
  children: null,
  showDimensions: true,
  imagePreview: '',
};

export default CardSection;
