/* eslint-disable react/prop-types,no-await-in-loop,no-param-reassign */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Check, Close } from '@material-ui/icons';
import { alert, business, memberships } from '../../../state';
import { TransactionTable } from '../../custom';
import CustomInputField from '../../custom/CustomInputField';
import ConfirmationDialog from '../../custom/ConfirmDialog';

const colsTitles = [
  'Membership #',
  'First Name',
  'Last Name',
  'Email',
  'Subscription Id',
  'CCOF ID',
  'Done',
];

const rowKeys = [
  'code',
  'firstName',
  'lastName',
  'email',
  'partnerSubscriptionId',
  'partnerCardOnFileId',
  'sent',
];

const MembershipSyncCcof = () => {
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const data = useSelector(card.selectors.selectPhysicalGiftCardList);
  const [currency, setCurrency] = useState('USD');
  const [businessData, setBusinessData] = React.useState({});

  // const currency = businessData.currency || 'USD';

  const currencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    }).format(value);
  const refForm = useRef();
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isConfirm, setIsConfirm] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const submit = async () => {
    await setIsLoading(true);
    const updatedArray = data;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < updatedArray.length; i++) {
      if (
        updatedArray[i].partnerCardOnFileId ||
        !updatedArray[i].partnerSubscriptionId
      )
        // eslint-disable-next-line no-continue
        continue;
      updatedArray[i].sent = 'loading';
      setData(updatedArray);
      setCurrentIndex(i);
      try {
        const resp = await dispatch(
          memberships.actions.syncCcof(updatedArray[i].id)
        );
        if (resp.payload) {
          updatedArray[i].partnerCardOnFileId =
            resp.payload?.partnerCardOnFileId;
          updatedArray[i].sent = !!updatedArray[i].partnerCardOnFileId;
          setData(updatedArray);
          openAlert({
            message: `Membership # ${resp.payload?.code} ccof is saved successfully`,
            severity: 'success',
          });
        } else if (resp.error) {
          updatedArray[i].sent = 'failed';
          setData(updatedArray);
          openAlert({
            message: `Failed to update Membership # ${updatedArray[i].code}!\nError message: ${resp.error?.message}`,
            severity: 'error',
          });
          break;
        }
      } catch (error) {
        console.log(error);
        break;
      }
      if (i === updatedArray.length) {
        openAlert({
          message: `All Memberships were created successfully`,
          severity: 'success',
        });
      }
    }
    await setIsLoading(false);
  };
  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  const getMemberships = async (businessId) => {
    const res = await dispatch(
      memberships.actions.getMemberships({ limit: 9999, businessId })
    );
    if (res.payload) {
      const resData = res.payload.data;
      resData.forEach((r) => {
        r.sent = r.partnerCardOnFileId || !r.partnerSubscriptionId;
      });
      setData(resData);
    }
  };

  return (
    <>
      <Box py={1}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={10} xs={12} lg={10}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: '',
                merchantName: '',
                posSystem: '',
              }}
              onSubmit={() => {}}
            >
              {(props) => {
                refForm.current = props;
                const getBusiness = async () => {
                  const res = await dispatch(
                    business.actions.getBusiness(props.values.businessId)
                  );
                  if (res.error) {
                    openAlert({
                      message: res.error?.message || 'Merchant is not found',
                      severity: 'error',
                    });
                    setBusinessData({});
                  } else {
                    setBusinessData(res.payload || {});
                    setCurrency(res.payload?.currency || 'USD');
                  }
                };
                return (
                  <Form>
                    <Grid container md={10} spacing={2}>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Merchant Name"
                          name="merchantName"
                          value={businessData.name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Pos System"
                          name="posSystem"
                          variant="outlined"
                          value={businessData.partner}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={getBusiness}
                          size="large"
                          disabled={!props.values.businessId}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg="auto">
                          <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            disabled={!businessData.id}
                            onClick={() => getMemberships(businessData.id)}
                          >
                            Get List of Members
                          </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            size="large"
                            style={{
                              backgroundColor: '#153a09',
                              color: '#ffffff',
                            }}
                            disabled={!data.length}
                          >
                            GET CCOF ID
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid item md={10} xs={12} lg={10}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <Typography>
                    Total remaining:{' '}
                    {data?.filter((r) => r.sent !== true)?.length}
                  </Typography>
                </Grid>
              </Grid>
            )}
            mods={{
              sent: (val, row) => {
                if (row.partnerCardOnFileId)
                  return (
                    <Check
                      color="secondary"
                      size={14}
                      style={{ color: 'green' }}
                    />
                  );
                switch (val) {
                  case true:
                    return (
                      <Check
                        color="secondary"
                        size={14}
                        style={{ color: 'green' }}
                      />
                    );
                  case 'loading':
                    return <CircularProgress color="primary" size={14} />;
                  case 'failed':
                    return <Close color="error" size={14} />;
                  default:
                    return '';
                }
              },
              amount: (val) => currencyFormat(val.toFixed(2)),
              // isExternal: (val) =>
              //   Number(val) === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />,
            }}
            pagination={{
              total: data.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            // isLoading={isLoading}
            key={currentIndex}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
      <ConfirmationDialog
        open={!!isConfirm}
        setOpen={setIsConfirm}
        title="No expiration date"
        content={`${isConfirm} entries have no expiration date. Do you want to continue?`}
        actionOk={() => {
          setIsConfirm(0);
          submit();
        }}
      />
      <ConfirmationDialog
        open={!!errorMessage}
        setOpen={setErrorMessage}
        title="Invalid Date Format"
        isConfirmation={false}
        content={errorMessage}
        actionOk={() => {
          setErrorMessage('');
        }}
      />
    </>
  );
};

export default MembershipSyncCcof;
