import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Select } from 'formik-mui';
import { FormControl } from '@mui/material';
import { alert, business, memberships } from '../../../../state';
import CustomInputField from '../../../custom/CustomInputField';

const AddSecondaryMerchantModal = ({ isOpen, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  const [currencySymbol, setCurrencySymbol] = useState(
    getSymbolFromCurrency('USD')
  );

  const formikRef = React.useRef(null);

  const primaryMerchants = useSelector(
    memberships.selectors.selectPrimaryMemberships
  );

  const [categories, setCategories] = React.useState([]);
  const [category, setCategory] = React.useState({});
  const [businessData, setBusinessData] = useState({});
  const amountRef = useRef(null);
  const currencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: businessData.currency || 'USD',
      minimumFractionDigits: 0,
    }).format(value);

  const getCategories = async (merchantId) => {
    const res = await dispatch(
      business.actions.getCategoryList({ merchantId })
    );
    if (res.payload) {
      setCategories(res.payload);
    }
  };

  useEffect(() => {
    if (businessData?.id) {
      getCategories(businessData.id);
    }
  }, [businessData]);
  const createSecondaryMerchant = async (values, ctx) => {
    setIsLoading(true);
    const formData = values;

    formData.discount = `${
      values.amount + (values.discountType === 0 ? '%' : currencySymbol)
    } off ${category.name || 'Whole Purchase'}`;
    const response = await dispatch(
      memberships.actions.createSecondaryMerchant({
        ...formData,
        businessId: businessData.id,
        // code,
      })
    );
    if (response.error) {
      openAlert({
        message: response.error.message || 'Something went wrong',
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: 'Secondary merchant is created successfully!',
        severity: 'success',
      });
      ctx.resetForm();
      dispatch(memberships.actions.getSecondaryMembershipsList());
      closeModal(true);
    }
    setIsLoading(false);
  };
  const colsTitles = ['Second Merchant Id', 'Create Date'];

  const rowKeys = ['code', 'createdAt'];
  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  headers.push({ label: 'Merchant Name', key: 'businessName' });

  const getBusiness = async (value) => {
    if (!value) {
      setBusinessData({});
      return;
    }
    const res = await dispatch(business.actions.getBusiness(value));
    if (res.error) {
      openAlert({
        message: res.error?.message || 'Merchant is not found',
        severity: 'error',
      });
      setBusinessData({});
    } else {
      setBusinessData(res.payload || {});
      setCurrencySymbol(getSymbolFromCurrency(res.payload?.currency || 'USD'));
    }
  };

  const body = (
    <Box py={2}>
      <Grid container spacing={2} style={{ flexGrow: 1 }}>
        <Grid item md={10} xs={12}>
          <Formik
            validationSchema={Yup.object({
              membershipPlanId: Yup.string().required('Required'),
              // code: Yup.string().required('Required'),
            })}
            initialValues={{
              businessId: '',
              membershipPlanId: '',
              name: '',
              partner: '',
              discountType: 0,
              targetScopeId: '',
              pricingRuleId: '',
            }}
            innerRef={formikRef}
            onSubmit={(values, ctx) => createSecondaryMerchant(values, ctx)}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              const handleSelectCategory = (event, val) => {
                setCategory(
                  categories.find((p) => p.id === val.props.value) || {}
                );
              };

              useEffect(() => {
                setFieldValue(
                  'name',
                  (primaryMerchants?.data || []).find(
                    (p) => p.id === values.membershipPlanId
                  )?.name
                );
              }, [values.membershipPlanId]);

              return (
                <Form>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Field
                          component={Select}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Select Primary Merchant"
                          name="membershipPlanId"
                          variant="outlined"
                          style={{ height: 47 }}
                        >
                          <MenuItem value="">---</MenuItem>
                          {(primaryMerchants?.data || []).map(
                            (opt) =>
                              opt && (
                                <MenuItem value={opt.id} key={opt.id}>
                                  {opt.business?.name}
                                </MenuItem>
                              )
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        disabled
                        type="text"
                        label="Primary Merchant Plan Name"
                        name="name"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box mt={4} />
                  <Typography variant="h5">Secondary Merchant: </Typography>
                  <Box mt={2} />
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        type="text"
                        placeholder="Enter Primary POS Merchant ID"
                        id="businessId"
                        name="businessId"
                        variant="outlined"
                        InputProps={{
                          onBlur: (e) => getBusiness(e.target.value),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        disabled
                        type="text"
                        placeholder="Business Name"
                        value={businessData?.name || ''}
                        name="businessName"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ position: 'relative' }}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        type="number"
                        removeArrows
                        name="amount"
                        variant="outlined"
                        required
                        label="Discount"
                        inputRef={amountRef}
                        // style={{ width: 320 }}
                        InputProps={{
                          style: {
                            paddingRight: 150,
                            minWidth: 320,
                            width: '100%',
                          },
                        }}
                      />
                      <ToggleButtonGroup
                        exclusive
                        id="roleType"
                        value={values.discountType}
                        // onChange={(e, val) => {
                        //   setFieldValue('discountType', val);
                        //   if (document.activeElement !== amountRef.current)
                        //     amountRef.current.focus();
                        // }}
                        size="small"
                        style={{ position: 'absolute', right: 30, top: 26 }}
                      >
                        <ToggleButton
                          value={0}
                          style={{ padding: '5px 25px' }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setFieldValue('discountType', 0);
                            if (document.activeElement !== amountRef.current)
                              amountRef.current.focus();
                          }}
                        >
                          %
                        </ToggleButton>
                        <ToggleButton
                          value={1}
                          style={{ padding: '5px 25px' }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setFieldValue('discountType', 1);
                            if (document.activeElement !== amountRef.current)
                              amountRef.current.focus();
                          }}
                        >
                          {currencySymbol}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={6}>
                      {currencySymbol === 'hidden' && ( // hide this block
                        <FormControl fullWidth>
                          <Field
                            component={Select}
                            autoWidth={false}
                            trim
                            fullWidth
                            required
                            type="text"
                            placeholder="Select Primary Merchant"
                            label="Category"
                            name="targetScopeId"
                            variant="outlined"
                            onChange={(event, val) =>
                              handleSelectCategory(event, val)
                            }
                          >
                            <MenuItem value={undefined}>---</MenuItem>
                            {categories.map(
                              (opt) =>
                                opt && (
                                  <MenuItem value={opt.id} key={opt.id}>
                                    {opt.name}
                                  </MenuItem>
                                )
                            )}
                          </Field>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>

                  <Box my={3}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={7}>
                        <Box
                          style={{ border: '1px solid #ccc', height: 38 }}
                          p={1}
                        >
                          {values.amount > 0
                            ? `members will get ${
                                values.discountType === 0
                                  ? `${values.amount}%`
                                  : currencyFormat(values.amount)
                              } off ${category.name || 'Whole Purchase'}`
                            : ''}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Button
                          style={{ marginRight: 12 }}
                          onClick={handleSubmit}
                          loading={isLoading}
                          disabled={isLoading || values.pricingRuleId}
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      {/* <DialogTitle>Primary Merchant</DialogTitle> */}
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddSecondaryMerchantModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddSecondaryMerchantModal;
