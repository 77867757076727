import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Select } from 'formik-mui';
import { FormControl } from '@mui/material';
import { alert, business } from '../../../../state';
import CustomInputField from '../../../custom/CustomInputField';
import franchises from '../../../../state/franchises';

const AddSecondaryMerchantModal = ({ isOpen, closeModal, type = 0 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));

  const formikRef = React.useRef(null);

  const franchisesList = useSelector(franchises.selectors.selectFranchisesList);

  useEffect(() => {
    // dispatch(franchises.actions.getFranchisesList({ limit: 1000 }));
  }, []);
  const createSecondaryMerchant = async (values, ctx) => {
    setIsLoading(true);
    const response = await dispatch(
      franchises.actions.createSecondaryMerchant({
        ...values,
      })
    );
    if (response.error) {
      openAlert({
        message: response.error?.message || 'Something went wrong',
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: 'Secondary merchant is created successfully!',
        severity: 'success',
      });
      ctx.resetForm();
      dispatch(franchises.actions.setFranchiseBusiness(response.payload));
      dispatch(franchises.actions.getSecondaryMerchants({ limit: 10, type }));
      closeModal(response.payload);
    }
    setIsLoading(false);
  };
  const colsTitles = ['Second Merchant Id', 'Create Date'];

  const rowKeys = ['code', 'createdAt'];
  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  headers.push({ label: 'Merchant Name', key: 'businessName' });
  const getBusiness = async (value) => {
    if (!value) return;
    const res = await dispatch(business.actions.getBusiness(value));
    if (res.error) {
      openAlert({
        message: res.payload?.message || 'Merchant is not found',
        severity: 'error',
      });
    }
  };
  const businessData = useSelector(business.selectors.selectBusinessData);

  const body = (
    <Box py={2}>
      <Grid container spacing={2} style={{ flexGrow: 1 }}>
        <Grid item md={10} xs={12}>
          <Formik
            validationSchema={Yup.object({
              primaryBusinessId: Yup.string().required('Required'),
              businessId: Yup.string().required('Required'),
            })}
            initialValues={{
              primaryBusinessId: '',
              franchiseId: '',
              name: '',
              merchantId: '',
              businessName: '',
              businessId: '',
              type,
            }}
            innerRef={formikRef}
            onSubmit={(values, ctx) => createSecondaryMerchant(values, ctx)}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              useEffect(() => {
                setFieldValue(
                  'name',
                  (franchisesList?.data || []).find(
                    (p) => p.business.id === values.primaryBusinessId
                  )?.name
                );
                setFieldValue(
                  'franchiseId',
                  (franchisesList?.data || []).find(
                    (p) => p.business.id === values.primaryBusinessId
                  )?.id
                );
                setFieldValue(
                  'merchantId',
                  (franchisesList?.data || []).find(
                    (p) => p.business.id === values.primaryBusinessId
                  )?.business?.merchantId
                );
              }, [values.primaryBusinessId]);

              return (
                <Form>
                  <Typography variant="h5">Add Secondary Merchant: </Typography>
                  <Box mt={4} />
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Field
                          component={Select}
                          fullWidth
                          required
                          type="text"
                          label="Select Primary Merchant"
                          name="primaryBusinessId"
                          variant="outlined"
                          style={{ height: 47 }}
                        >
                          <MenuItem value="">---</MenuItem>
                          {(franchisesList?.data || [])
                            .filter((f) => f.enabled)
                            .map(
                              (opt) =>
                                opt && (
                                  <MenuItem
                                    value={opt.business?.id}
                                    key={opt.business?.id}
                                  >
                                    {opt.business?.name}
                                  </MenuItem>
                                )
                            )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        disabled
                        type="text"
                        label="Group Name / Franchise Name"
                        name="name"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Box mt={2} />
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        disabled
                        type="text"
                        placeholder="Square - Primary Merchant Id"
                        name="merchantId"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        type="text"
                        placeholder="Pintuna - Primary Merchant Id"
                        id="primaryBusinessId"
                        name="primaryBusinessId"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2} />

                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        required
                        type="text"
                        placeholder="Enter Pintuna - Secondary Merchant ID"
                        id="businessId"
                        name="businessId"
                        variant="outlined"
                        InputProps={{
                          onBlur: (e) => getBusiness(e.target.value),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        disabled
                        type="text"
                        placeholder="Business Name"
                        name="businessName"
                        value={businessData.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2} />

                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        trim
                        fullWidth
                        disabled
                        type="text"
                        placeholder="Square - Secondary Merchant Id"
                        name="businessDataId"
                        value={businessData.merchantId}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box mt={4}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          style={{ marginRight: 12 }}
                          onClick={handleSubmit}
                          loading={isLoading}
                          disabled={isLoading}
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      {/* <DialogTitle>Primary Merchant</DialogTitle> */}
      <DialogContent>{body}</DialogContent>
      <IconButton
        aria-label="clear"
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 20,
          height: 20,
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

AddSecondaryMerchantModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.number.isRequired,
};

export default AddSecondaryMerchantModal;
