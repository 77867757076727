/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { CardGiftcard, Business } from '@material-ui/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import SEO from '../../seo';
import { InfoCard, TransactionTable } from '../../custom';
import SummaryTable from './SummaryTable';
import { stats } from '../../../state';

const useStyles = makeStyles(() => ({
  cardOut: {
    padding: '20px',
    justifyContent: 'center',
    width: '100%',
    marginTop: '40px',
  },
  card: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
}));

const colsTitles = [
  'Business Name',
  'Currency',
  'Cards Sold',
  'Total Value',
  'Average Value',
];
const rowKeys = [
  'businessName',
  'currency',
  'cardsSold',
  'totalValue',
  'averageValue',
];
const mods = {
  totalValue: (val, row) =>
    `${getSymbolFromCurrency(row.currency) || ''}${val.toFixed(2)}`,
  averageValue: (val, row) =>
    `${getSymbolFromCurrency(row.currency) || ''}${val.toFixed(2)}`,
};
const MemberShips = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    membershipsCount,
    totalMembershipsSold,
    membershipsSummary = [],
    // summaryMerchantsCount = 0,
    membershipsBusinessSummary = [],
  } = data;
  const [params, setParams] = useState({ page: 1, limit: 10 });

  // On First Load
  React.useEffect(() => {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = date;

    dispatch(
      stats.actions.getMembershipStats({
        startDate: start,
        endDate: end,
        limit: params.limit,
        page: params.page,
      })
    );
  }, []);

  const pagination = {
    total: membershipsBusinessSummary.length,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, p) => setParams({ ...params, page: p + 1 }),
    handleChangeRowsPerPage: (event) =>
      setParams({ ...params, pageSize: event.target.value, page: 1 }),
  };

  const refreshData = (range) => {
    dispatch(
      stats.actions.getMembershipStats({
        startDate: range.startDate,
        endDate: range.endDate,
        limit: params.limit,
        page: 1,
      })
    );
  };

  return (
    <>
      <SEO title="Dashboard - MemberShips" />
      <Box pb={2}>
        <Typography variant="h5">Dashboard - MemberShips</Typography>
      </Box>
      <Divider />
      <Grid container spacing={2} justifyContent="center" xs="auto" mt={2}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sm={6}
            md={3}
            className={classes.cardOut}
            spacing={3}
            p={2}
          >
            <InfoCard
              label="# of Memberships"
              icon={<Business fontSize="large" />}
              isLoading={false}
              onClick={() => {}}
            >
              {membershipsCount}
            </InfoCard>
          </Grid>
          <Grid xs={12} sm={6} md={3} className={classes.cardOut}>
            <InfoCard
              label="Memberships SOLD"
              icon={<CardGiftcard fontSize="large" />}
              isLoading={false}
              onClick={() => {}}
            >
              {totalMembershipsSold}
            </InfoCard>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={12} className={classes.cardOut}>
            <SummaryTable
              label="Memberships Summary"
              isLoading={false}
              data={membershipsSummary}
              refreshData={refreshData}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className={classes.cardOut} md="12" lg="10" xl="8">
            <Box>
              <TransactionTable
                cols={colsTitles}
                rowKeys={rowKeys}
                data={(data.membershipsBusinessSummary || []).slice(
                  (params.page - 1) * params.limit,
                  params.page * params.limit
                )}
                mods={mods}
                // isLoading={isLoading}
                uniqueIdBy="code"
                noDataMsg="Hmm... Looks like there is no data."
                pagination={pagination}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

MemberShips.propTypes = {
  data: PropTypes.shape({
    membershipsCount: PropTypes.number,
    totalMembershipsSold: PropTypes.number,
    summaryMerchantsCount: PropTypes.number,
    membershipsSummary: PropTypes.objectOf(PropTypes.any).isRequired,
    membershipsBusinessSummary: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  data: stats.selectors.selectMembershipStats(state),
});

export default connect(mapStateToProps)(MemberShips);
