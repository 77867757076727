import { Tab, withStyles } from '@material-ui/core';

const StyledTab = withStyles({
  root: {
    minWidth: 'auto',
    marginRight: '20px',
    padding: '0',
    textTransform: 'capitalize',
    color: '#0969da',
  },
})(Tab);

export default StyledTab;
