/* eslint-disable react/prop-types,no-plusplus */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { alert, business, card } from '../../../../state';
import { PartnerEnum } from '../../../../utils/constants';
import { TransactionTable } from '../../../custom';
import CustomInputField from '../../../custom/CustomInputField';

const colsTitles = [
  '#',
  'Date',
  'Merchant Id',
  'Merchant Name',
  'Physical Card #',
  'QR Code / Barcode',
];

const rowKeys = [
  'id',
  'createdAt',
  'businessId',
  'merchantName',
  'code',
  'qrCode',
];

export const GenerateNewCard = () => {
  const generateLuhnNumber = (numDigits, prefix) => {
    let number = prefix.toString();

    // Generate random digits for the remaining length
    for (let i = prefix.toString().length; i < numDigits - 1; i++) {
      number += Math.floor(Math.random() * 10);
    }

    // Calculate the Luhn check digit
    let sum = 0;
    let isEven = numDigits % 2 === 0;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number.charAt(i), 10);

      if (isEven) {
        const doubledDigit = digit * 2;
        digit = doubledDigit > 9 ? doubledDigit - 9 : doubledDigit;
      }

      sum += digit;
      isEven = !isEven;
    }

    const checkDigit = (10 - (sum % 10)) % 10;
    return number + checkDigit;
  };

  const makeGiftCardCode = (partner, startingDigits) => {
    if (partner === PartnerEnum.Square) {
      return generateLuhnNumber(16, startingDigits);
    }
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (
      let i = 0;
      i < (PartnerEnum.Square ? 16 - startingDigits.length : 16);
      i += 1
    ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return (PartnerEnum.Square ? startingDigits : '') + result;
  };
  const [physicalGiftCards, setPhysicalGiftCard] = useState([]);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));

  const refLink = useRef();
  const refForm = useRef();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const generateGiftCardCode = async (values) => {
    setIsLoading(true);
    const resp = await dispatch(
      business.actions.getBusiness(values.businessId)
    );
    if (resp.error) {
      openAlert({
        message:
          (resp.payload && resp.payload.message) || 'Invalid merchant Id',
        severity: 'error',
      });
      setIsLoading(false);
      return;
    }
    refForm.current?.setValues({
      ...refForm.current?.values,
      merchantName: resp.payload.name,
      posSystem: resp.payload.partner,
    });
    const codes = [...physicalGiftCards];
    const { length } = codes;
    for (let i = 0; i < Number(values.amount); i += 1) {
      const code = makeGiftCardCode(
        resp.payload.partner,
        values.startingDigits
      );
      codes.push({
        id: length + i + 1,
        createdAt: moment().format('MMMM DD YYYY, HH:mm:ss'),
        code: code.replace(/(.{4})/g, '$1 '),
        qrCode:
          (resp.payload.partner === PartnerEnum.Square ? 'sqgc://' : '') + code,
        businessId: values.businessId,
        merchantName: resp.payload.name,
      });
    }
    setPhysicalGiftCard(codes);
    setTimeout(() => setIsLoading(false), 200);
  };

  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  // headers.push({ label: 'Merchant Name', key: 'businessName' });
  const saveAndExport = async () => {
    const res = await dispatch(
      card.actions.physicalGiftCards({
        data: physicalGiftCards.map((item) => ({
          ...item,
          code: item.code.replace(/\s/g, ''),
        })),
      })
    );
    if (res.payload && !res.error) {
      await openAlert({
        message:
          (res.payload && res.payload.message) || 'Successfully saved cards',
      });
      refLink.current.link.click();
      window.location.reload();
    }
  };
  return (
    <>
      <Box py={2}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={10} xs={12}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
                amount: Yup.string().required('Required'),
                startingDigits: Yup.number()
                  .required('Required')
                  .min('1000', 'Should be between 4 digits and 10 digits')
                  .max(
                    '9999999999',
                    'Should be between 4 digits and 10 digits'
                  ),
              })}
              initialValues={{
                businessId: '',
                merchantName: '',
                posSystem: '',
                startingDigits: '156023',
              }}
              onSubmit={(values) => {
                generateGiftCardCode(values);
                return true;
              }}
            >
              {(props) => {
                const { handleSubmit } = props;
                refForm.current = props;
                const getBusiness = async () => {
                  if (!props.values.businessId) return;
                  setIsLoading(true);
                  const resp = await dispatch(
                    business.actions.getBusiness(props.values.businessId)
                  );
                  if (resp.error) {
                    openAlert({
                      message:
                        (resp.payload && resp.payload.message) ||
                        'Invalid merchant Id',
                      severity: 'error',
                    });
                    setIsLoading(false);
                    return;
                  }
                  refForm.current?.setValues({
                    ...refForm.current?.values,
                    merchantName: resp.payload.name,
                    posSystem: resp.payload.partner,
                  });
                  setIsLoading(false);
                };

                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                          onBlur={getBusiness}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Merchant Name"
                          name="merchantName"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Pos System"
                          name="posSystem"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          removeArrows
                          type="number"
                          placeholder="# of cards required"
                          name="amount"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          removeArrows
                          type="number"
                          placeholder="Enter starting digits"
                          name="startingDigits"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          size="large"
                        >
                          {'Generate Physical \nCard #sss'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid item xs="12" lg="10" xl="8">
          <CSVLink
            data={physicalGiftCards}
            filename="physicalGiftCards.csv"
            className="hidden"
            ref={refLink}
            target="_blank"
            headers={headers}
          />
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={physicalGiftCards.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            mods={{}}
            pagination={{
              total: physicalGiftCards.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <Typography
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#0969da',
                      float: 'left',
                    }}
                    disabled={physicalGiftCards.length === 0}
                    onClick={saveAndExport}
                  >
                    Export in CSV and Save
                  </Typography>
                </Grid>
                {/* {refForm.current?.values.posSystem === PartnerEnum.Square &&
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClickUploadCardsSquare}
                      size="small"
                      disabled={physicalGiftCards.length === 0}
                    >
                      Upload Cards into Square
                    </Button>
                  </Grid>
                } */}
              </Grid>
            )}
            isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
    </>
  );
};

export default { GenerateNewCard };
