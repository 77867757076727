/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-await */
import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

import EnlargeImageModal from './EnlargeImageModal';
import Editing from '../../../../images/editing.png';
import ConfirmationDialog from './ConfirmDialog';
import CardSection from './CardSection';
import { TransactionTable } from '../../../custom';
import { alert, card } from '../../../../state';

const useStyles = makeStyles(() => ({
  viewBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const GiftCardImages = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [imagesEnlarge, setImagesEnlarge] = React.useState('');
  const [loading, setIsLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [idSeleted, setIdSelected] = useState('');
  const [giftCardImageDetail, setGiftCardImageDetail] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const openAlert = (message) =>
    dispatch(dispatch(alert.actions.open(message)));

  const handleOpenPreview = (url) => {
    setImagesEnlarge(url);
    setOpenModal(true);
  };

  const defaultGiftCardImage =
    'https://egift-frontend-production-f.squarecdn.com/card-assets/2018_Just_Because_Bow_e-Gift_Cards_640x400.png';

  const getGiftCardImages = async () => {
    const response = await dispatch(card.actions.getGiftCardImages());
    if (response.payload) {
      setData(response.payload);
    }
  };

  const updateStatusGiftCardImages = async (item) => {
    if (loadingAction) {
      return;
    }
    setIdSelected(item.id);
    setLoadingAction(true);
    const payload = {
      id: item.id,
      status: item.status === 0 ? 1 : 0,
    };

    const response = await dispatch(card.actions.updateGiftCardImages(payload));

    if (response.payload) {
      openAlert({
        message: 'Gift Card Image updated successfully',
        severity: 'success',
      });
      getGiftCardImages();
    }
    setLoadingAction(false);
  };

  const setGiftCardImage = async (val) => {
    return await dispatch(card.actions.setGiftCardImage(val));
  };

  const handleSuccess = async (url) => {
    formikRef.current.setFieldValue('url', url);
  };

  const editGiftCardImage = async (item) => {
    setSelectedItem(item);
    formikRef.current.setFieldValue('url', item.url);
  };

  const handleSubmitGiftCard = async (values) => {
    setIsLoading(true);
    try {
      const body = {
        id: selectedItem?.id,
        url: values.url,
      };
      const action = selectedItem
        ? card.actions.createGiftCardImages(body)
        : card.actions.createGiftCardImages(values);
      const response = await dispatch(action);

      formikRef.current.resetForm();

      if (response.payload) {
        openAlert({
          message: 'Gift Card Image created successfully',
          severity: 'success',
        });
        setSelectedItem(null);
        getGiftCardImages();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const removeGiftCardImage = async () => {
    if (!giftCardImageDetail.id) return;
    setLoadingAction(true);
    const response = await dispatch(
      card.actions.deleteGiftCardImages(giftCardImageDetail.id)
    );
    setLoadingAction(false);
    if (response.payload) {
      openAlert({
        message: 'Gift Card Image deleted successfully',
        severity: 'success',
      });
      getGiftCardImages();
    }
  };

  React.useEffect(() => {
    getGiftCardImages();
  }, []);

  const cols = ['Language', 'Occasion', 'Image', 'Enlarge', 'Active', ''];
  const rowKeys = [
    'languageId',
    'occasionId',
    'url',
    'enlarge',
    'status',
    'id',
  ];

  const mods = {
    languageId: () => {
      return <Typography>English</Typography>;
    },
    occasionId: () => {
      return <Typography>Default</Typography>;
    },
    url: (val) => {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={val} style={{ width: 50 }} />;
    },
    // eslint-disable-next-line no-unused-vars
    enlarge: (id, row) => {
      return (
        <Typography
          onClick={() => handleOpenPreview(row.url)}
          className={classes.viewBtn}
        >
          View
        </Typography>
      );
    },
    status: (id, row) => (
      <Typography
        style={{
          color: row.status ? 'blue' : '#1CBF3C',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={() => updateStatusGiftCardImages(row)}
      >
        {loadingAction && idSeleted === row.id ? (
          <CircularProgress size={24} />
        ) : row.status ? (
          'No'
        ) : (
          'Yes'
        )}
      </Typography>
    ),
    id: (id, row) => (
      <>
        <Tooltip title="Edit" placement="top" arrow>
          <IconButton
            aria-haspopup="true"
            cursor="pointer"
            onClick={() => editGiftCardImage(row)}
          >
            <img src={Editing} alt="" style={{ width: 20 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top" arrow>
          <IconButton
            onClick={() => setGiftCardImageDetail(row)}
            cursor="pointer"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </>
    ),
  };

  const filterGiftCardImage = useMemo(() => {
    if (data && data.length > 0) {
      return data.filter((item) => !item?.isDeleted);
    }
    return [];
  }, [data]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      validationSchema={Yup.object({
        url: Yup.string().required('This field is required'),
      })}
      initialValues={{
        url: defaultGiftCardImage,
        occasionId: 'default',
        languageId: 'english',
      }}
      onSubmit={async (values) => {
        handleSubmitGiftCard(values);
      }}
    >
      {({ values, errors, handleSubmit, touched, handleChange }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid
                item
                container
                xs={12}
                md={3}
                style={{ alignItems: 'flex-start' }}
              >
                <Field
                  component={FormikTextField}
                  onChange={handleChange}
                  helperText={
                    errors.languageId && touched.languageId
                      ? errors.languageId
                      : null
                  }
                  error={!!(errors.languageId && touched.languageId)}
                  fullWidth
                  select
                  value={values.languageId}
                  disabled
                  name="languageId"
                  variant="outlined"
                  label="Language:"
                >
                  <MenuItem selected value="english">
                    English
                  </MenuItem>
                </Field>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={3}
                style={{ alignItems: 'flex-start' }}
              >
                <Field
                  component={FormikTextField}
                  onChange={handleChange}
                  helperText={
                    errors.occasionId && touched.occasionId
                      ? errors.occasionId
                      : null
                  }
                  error={!!(errors.occasionId && touched.occasionId)}
                  fullWidth
                  select
                  disabled
                  value={values.occasionId}
                  name="occasionId"
                  variant="outlined"
                  label="Occasion:"
                >
                  <MenuItem selected value="default">
                    Default
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item container xs={12} md={6}>
                <CardSection
                  isLoading={loading}
                  merchantCreatives={selectedItem}
                  setGiftCardImage={setGiftCardImage}
                  handleSuccess={handleSuccess}
                  field="url"
                  imagePreview={values.url}
                />
                <small style={{ color: '#f44336' }}>{errors.url}</small>
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? <CircularProgress size={24} /> : 'Add'}
                </Button>
              </Grid>
            </Grid>

            <Grid container item md={9} xs={12} style={{ marginTop: 20 }}>
              <TransactionTable
                mt={3}
                cols={cols}
                rowKeys={rowKeys}
                data={filterGiftCardImage}
                mods={mods}
                noDataMsg="Hmm... Looks like there are no images yet."
                isMinHeight={false}
                isLoading={loading}
                size="small"
              />
            </Grid>

            <EnlargeImageModal
              url={imagesEnlarge}
              open={openModal}
              setOpen={setOpenModal}
            />

            <ConfirmationDialog
              open={giftCardImageDetail}
              setOpen={setGiftCardImageDetail}
              title="Confirmation"
              content="Do you really want to delete?"
              actionOk={() => removeGiftCardImage()}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default GiftCardImages;
