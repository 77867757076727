import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: { backgroundColor: '#000', color: '#fff', textAlign: 'center' },
    root: {
      padding: 10,
      width: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      width: '80%',
      maxHeight: 435,
    },
    wrapModal: {
      minWidth: 300,
      paddingTop: '0px !important',
      padding: 0,
    },
  })
);

function ConfirmationDialog({
  open,
  setOpen,
  actionOk,
  title,
  content,
  isConfirmation = true,
}) {
  const classes = useStyles();
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    await actionOk();
    setOpen(false);
  };

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent className={classes.wrapModal}>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          {isConfirmation ? (
            <>
              <Button autoFocus onClick={handleCancel} color="primary">
                No
              </Button>
              <Button onClick={handleOk} color="primary">
                Yes
              </Button>
            </>
          ) : (
            <Button autoFocus onClick={handleOk} color="primary">
              Ok
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  actionOk: PropTypes.func.isRequired,
  isConfirmation: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  isConfirmation: true,
};

export default ConfirmationDialog;
