/* eslint-disable react/prop-types */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { alert, business, card } from '../../../../state';
import { PartnerEnum } from '../../../../utils/constants';
import { TransactionTable } from '../../../custom';
import CustomInputField from '../../../custom/CustomInputField';

const colsTitles = [
  '#',
  'Date',
  'Merchant Id',
  'Merchant Name',
  'Physical Card #',
  'QR Code / Barcode',
];

const rowKeys = [
  'index',
  'createdAt',
  'businessId',
  'merchantName',
  'code',
  'qrCode',
];

export const LookupExistingCard = () => {
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const data = useSelector(card.selectors.selectPhysicalGiftCardList);

  const refForm = useRef();
  const refLink = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  const getPhysicalCard = async (values) => {
    setIsLoading(true);
    const resp1 = await dispatch(
      business.actions.getBusiness(values.businessId)
    );
    if (resp1.error) {
      openAlert({
        message:
          (resp1.payload && resp1.payload.message) || 'Invalid merchant Id',
        severity: 'error',
      });
      setIsLoading(false);
      return;
    }
    refForm.current?.setValues({
      ...refForm.current?.values,
      merchantName: resp1.payload.name,
      posSystem: resp1.payload.partner,
    });

    const resp2 = await dispatch(
      card.actions.getPhysicalGiftCardsList(values.businessId)
    );
    if (resp2.error) {
      openAlert({
        message:
          (resp2.payload && resp2.payload.message) || 'Invalid merchant Id',
        severity: 'error',
      });
      setIsLoading(false);
      return;
    }
    setData(
      resp2.payload.map((e, index) => ({
        ...e,
        index: index + 1,
        code: e.code.replace(/(.{4})/g, '$1 '),
        merchantName: resp1.payload.name,
        qrCode:
          (resp1.payload.partner === PartnerEnum.Square ? 'sqgc://' : '') +
          e.code,
        createdAt: moment(e.createdAt).format('MMMM DD YYYY, HH:mm:ss'),
      }))
    );
    setTimeout(() => setIsLoading(false), 200);
  };

  const onExportCSV = () => {
    refLink.current.link.click();
  };

  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  return (
    <>
      <Box py={2}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={10} xs={12}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: '',
                merchantName: '',
                posSystem: '',
              }}
              onSubmit={getPhysicalCard}
            >
              {(props) => {
                const { handleSubmit } = props;
                refForm.current = props;
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Merchant Name"
                          name="merchantName"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Pos System"
                          name="posSystem"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          size="large"
                        >
                          Get Physical Card #s
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid item xs="12" lg="10" xl="8">
          <CSVLink
            data={data}
            filename="physicalGiftCards.csv"
            className="hidden"
            ref={refLink}
            target="_blank"
            headers={headers}
          />
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            mods={{}}
            pagination={{
              total: data.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <Typography
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#0969da',
                      float: 'left',
                    }}
                    disabled={data.length === 0}
                    onClick={onExportCSV}
                  >
                    Export in CSV
                  </Typography>
                </Grid>
              </Grid>
            )}
            isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
    </>
  );
};

export default { LookupExistingCard };
