import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import SEO from '../../seo';
import { TransactionTable } from '../../custom';
import { alert, enterprises } from '../../../state';
import { PartnerEnum } from '../../../utils/constants';

const colsTitles = [
  'Merchant Name',
  'Device Type Name',
  'Model',
  'Serial#',
  'Merchant Id',
  'Order Prefix',
];

const rowKeys = [
  'merchantName',
  'deviceTypeName',
  'model',
  'serial',
  'merchantId',
  'orderPrefix',
];
export const PosClover = () => {
  const dispatch = useDispatch();
  const { total, data } = useSelector(enterprises.selectors.selectPosDevices);
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    merchantId: '',
    partnerMerchantId: '',
    partner: PartnerEnum.Clover,
    update: new Date().getTime(),
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const openAlert = (val) => dispatch(dispatch(alert.actions.open(val)));

  const getDeviceList = async (pr) => {
    // eslint-disable-next-line no-param-reassign
    if (!pr) pr = params;
    if (isEmpty(params.merchantId)) return;
    setIsLoading(true);
    const res = await dispatch(
      enterprises.actions.getPosDeviceList({
        ...pr,
        partnerMerchantId: pr.merchantId,
      })
    );
    if (res.error) {
      openAlert({
        message: res.error.message || 'merchant is not foundId',
        severity: 'error',
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const mods = {};
  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage: (event, page) => {
      setParams({ ...params, page: page + 1 });
      getDeviceList({ ...params, page: 1 });
    },
    handleChangeRowsPerPage: (event) => {
      setParams({ ...params, limit: event.target.value, page: 1 });
      getDeviceList({ ...params, limit: event.target.value, page: 1 });
    },
  };
  return (
    <>
      <SEO title="Clover - Merchant Devices" />
      <Box pb={2}>
        <Typography variant="h5">Clover - Merchant Devices</Typography>
      </Box>
      <Divider />
      <Grid md={10} xs={12} lg={8}>
        <Box py={2}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data}
            mods={mods}
            isLoading={isLoading}
            pagination={pagination}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    required
                    type="text"
                    label="Enter Merchant ID"
                    size="small"
                    name="name"
                    onChange={(e) =>
                      setParams({ ...params, merchantId: e.target.value })
                    }
                    onKeyPress={(e) => e.charCode === '13' && getDeviceList()}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={isLoading}
                    onClick={() => getDeviceList()}
                    variant="contained"
                    color="primary"
                  >
                    Get Device List
                  </Button>
                </Grid>
              </Grid>
            )}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>
    </>
  );
};

export default PosClover;
