/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import SEO from '../../seo';
import { memberships } from '../../../state';
import { TransactionTable } from '../../custom';
import AddPrimaryMerchantModal from './Modal/AddPrimaryMerchantModal';
// import AddDiscountForm from './AddDiscountForm';
// import ConfirmationDialog from '../GiftCardDetails/ConfirmDialog';

const colsTitles = [
  'Merchant Name',
  'Square Merchant Id',
  'Pintuna Merchant Id',
  'Discount Code Type',
  'Program Name',
  'Enable',
];

const rowKeys = [
  'business.name',
  'business.merchantId',
  'business.id',
  'discountCodeType',
  'name',
  'sharingStatus',
];

const PrimaryMerchant = () => {
  const PintunaAppUrl =
    process.env.APP_PORTAL_URL || 'https://app.pintuna.com/';
  const [params] = React.useState({ limit: 10, page: 1 });
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(memberships.actions.getPrimaryMembershipsList(params));
  }, [params]);

  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const membershipPlans = useSelector(
    memberships.selectors.selectPrimaryMemberships
  );

  useEffect(() => {}, [membershipPlans]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const updateSharingStatus = async (row) => {
    await dispatch(
      memberships.actions.createPrimaryMerchant({
        businessId: row.business?.id,
        membershipPlanId: row.id,
        status: row.sharingStatus === 1 ? 2 : 1,
      })
    );
    dispatch(memberships.actions.getPrimaryMembershipsList(params));
  };
  const mods = {
    name: (val, row) => (
      <Link
        href={`${PintunaAppUrl}membership/${row.membershipConfigId}/register`}
        underline="hover"
        color="initial"
        target="_blank"
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
          color: '#0969da',
        }}
      >
        {val}
      </Link>
    ),
    sharingStatus: (id, row) =>
      row.sharingStatus === 1 ? (
        <CheckBox
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateSharingStatus(row)}
        />
      ) : (
        <CheckBoxOutlineBlank
          style={{
            cursor: 'pointer',
          }}
          onClick={() => updateSharingStatus(row)}
        />
      ),
    discountCodeType: (val) => (val === 0 ? 'QR Code' : 'Barcode'),
  };

  return (
    <>
      <SEO title="Membership discount" />
      <AddPrimaryMerchantModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
      <Grid md={12} xl={8}>
        <Box py={2}>
          <Button
            disabled={isLoading}
            onClick={() => handleOpenModal(true)}
            variant="contained"
            color="primary"
          >
            Add Primary Merchant
          </Button>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={membershipPlans?.data || []}
            mods={mods}
            isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Box>
      </Grid>
    </>
  );
};

export default PrimaryMerchant;
