/* eslint-disable react/prop-types,no-await-in-loop,no-param-reassign */
import { Box, Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { alert, business, loyalties } from '../../../state';
import { TransactionTable } from '../../custom';
import CustomInputField from '../../custom/CustomInputField';
import ConfirmationDialog from '../../custom/ConfirmDialog';

const colsTitles = [
  'Id',
  'First Name',
  'Last Name',
  'Email',
  'Phone',
  'Reference Id',
  'Note',
  'Created Date',
];

const rowKeys = [
  'id',
  'firstName',
  'lastName',
  'email',
  'phone',
  'referenceId',
  'note',
  'createdAt',
];

const CustomerInfo = () => {
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const data = useSelector(card.selectors.selectPhysicalGiftCardList);
  const [businessData, setBusinessData] = React.useState({});

  // const currency = businessData.currency || 'USD';

  const refForm = useRef();
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  return (
    <>
      <Box py={1}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={10} xs={12} lg={10}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: '',
                merchantName: '',
                posSystem: '',
                customerId: '',
              }}
              onSubmit={async (values) => {
                await setIsLoading(true);
                const resp = await dispatch(
                  loyalties.actions.getCustomer({
                    businessId: values.businessId,
                    customerId: values.customerId,
                  })
                );
                if (resp.payload) {
                  setData([resp.payload]);
                  openAlert({
                    message: `Get customer info successfully`,
                    severity: 'success',
                  });
                } else if (resp.error) {
                  openAlert({
                    message: `Failed to get customer info ${resp.error?.message}`,
                    severity: 'error',
                  });
                }
                await setIsLoading(false);
              }}
            >
              {(props) => {
                refForm.current = props;
                const getBusiness = async () => {
                  const res = await dispatch(
                    business.actions.getBusiness(props.values.businessId)
                  );
                  if (res.error) {
                    openAlert({
                      message: res.error?.message || 'Merchant is not found',
                      severity: 'error',
                    });
                    setBusinessData({});
                  } else {
                    setBusinessData(res.payload || {});
                  }
                };
                return (
                  <Form>
                    <Grid container md={10} spacing={2}>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Merchant Name"
                          name="merchantName"
                          value={businessData.name}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Pos System"
                          name="posSystem"
                          variant="outlined"
                          value={businessData.partner}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={getBusiness}
                          size="large"
                          disabled={!props.values.businessId}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg="auto">
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            trim
                            fullWidth
                            type="text"
                            placeholder="Enter Customer ID"
                            name="customerId"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{
                              backgroundColor: '#153a09',
                              color: '#ffffff',
                            }}
                            onClick={props.handleSubmit}
                          >
                            Get Square Customer Data
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid item md={10} xs={12} lg={10}>
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            mods={{
              createdAt: (val) =>
                moment(`${val}Z`, 'DD/MM/YYYY').format('MMMM DD YYYY'),
            }}
            pagination={{
              total: data.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            // isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
      <ConfirmationDialog
        open={!!errorMessage}
        setOpen={setErrorMessage}
        title="Invalid Date Format"
        isConfirmation={false}
        content={errorMessage}
        actionOk={() => {
          setErrorMessage('');
        }}
      />
    </>
  );
};

export default CustomerInfo;
