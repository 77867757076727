import React from 'react';

import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import { PosClover } from './PosClover';

export const PosDevices = () => {
  return (
    <Router basepath={withPrefix('dashboard/pos-devices')}>
      <PosClover path="clover" />
    </Router>
  );
};

export default PosDevices;
