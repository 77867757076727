/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

// import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getOr } from 'lodash/fp';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import getSymbolFromCurrency from 'currency-symbol-map';
import SEO from '../../seo';
import { alert, business } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const DiscountCode = () => {
  const [merchantCodes, setMerchantCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  const [currencySymbol, setCurrencySymbol] = useState(
    getSymbolFromCurrency('USD')
  );

  const [categories, setCategories] = React.useState([]);
  const [category, setCategory] = React.useState({});
  const [discountCode, setDiscountCode] = React.useState({});
  const [secondDiscountCode, setSecondDiscountCode] = React.useState({});
  const [businessData, setBusinessData] = React.useState({});

  const amountRef = useRef(null);
  const currencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: businessData.currency || 'USD',
      minimumFractionDigits: 0,
    }).format(value);
  const updateMerchantCode = async (id, ctx) => {
    if (!id) return;
    const res = await dispatch(business.actions.getBusiness(id));
    if (res.payload) {
      setBusinessData(res.payload);
      setMerchantCodes(getOr([], ['payload', 'items'], res));
      ctx.setFieldValue('businessName', res.payload.name);
      ctx.setFieldValue('pos', res.payload.partner);
      setCurrencySymbol(getSymbolFromCurrency(res.payload.currency));
      setIsLoading(false);
    }
  };

  const verifyDiscountCode = async (values, ctx) => {
    const response = await dispatch(
      business.actions.getBusiness(values.businessId)
    );
    if (response.payload) {
      ctx.setFieldValue('businessName', response.payload.name);
    } else {
      ctx.setFieldValue('businessName', '');
    }

    const res = await dispatch(business.actions.getDiscountCode(values));
    if (res.payload) {
      openAlert({
        message: 'Discount Code is get successfully!',
        severity: 'success',
      });
      setSecondDiscountCode(res.payload);
    }
    if (res.error) {
      openAlert({
        message: res.error?.message || 'Discount Code is not found!',
        severity: 'error',
      });
    }
  };

  const getCategories = async (merchantId) => {
    const res = await dispatch(
      business.actions.getCategoryList({ merchantId })
    );
    if (res.payload) {
      setCategories(res.payload);
    }
  };

  useEffect(() => {
    console.log(businessData);
    if (businessData?.id) {
      getCategories(businessData.id);
    }
  }, [businessData]);
  const createPricingRule = async (values, ctx) => {
    setIsLoading(true);
    const formData = values;

    formData.discount = `${
      values.amount + (values.discountType === 0 ? '%' : currencySymbol)
    } off ${category.name || 'Whole Purchase'}`;
    const response = await dispatch(
      business.actions.createPricingRule({
        ...formData,
        businessId: businessData.id,
        // code,
      })
    );
    if (response.error) {
      openAlert({
        message: response.error.message || 'Invalid merchant Id',
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: 'Pricing rule is created successfully!',
        severity: 'success',
      });
      ctx.setFieldValue('pricingRuleId', response.payload.id);
    }
    setIsLoading(false);
  };
  const colsTitles = ['Second Merchant Id', 'Create Date'];
  const mods = {
    createdAt: (val) => moment(`${val}Z`).format('MMMM DD YYYY, HH:mm:ss'),
  };

  const rowKeys = ['code', 'createdAt'];
  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));
  headers.push({ label: 'Merchant Name', key: 'businessName' });

  const createDiscountCode = async (val, setFieldValue) => {
    setIsLoading(true);
    const response = await dispatch(
      business.actions.createDiscountCode({
        ...val,
        businessId: businessData.id,
        expirationDays: val.expirationDays || 0,
      })
    );
    if (response.error) {
      openAlert({
        message: response.error.message || 'Invalid merchant Id',
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: 'Discount Code is created successfully!',
        severity: 'success',
      });
      setDiscountCode(response.payload);
      setFieldValue('code', response.payload?.discountCode?.code);
    }
    setIsLoading(false);
  };

  return (
    <>
      <SEO title="Map Merchant IDs" />
      <Box pb={2}>
        <Typography variant="h5">Create Discount code</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item md={8} xs={12}>
            <Formik
              validationSchema={Yup.object({
                merchantId: Yup.string().required('Required'),
                // code: Yup.string().required('Required'),
              })}
              initialValues={{
                merchantId: '', // a6a900a9-5661-4721-a035-59469bd3027d
                code: '',
                name: '',
                partner: '',
                discountType: 0,
                targetScopeId: '',
                expirationDays: 7,
                pricingRuleId: '', // INNWSCUL6JVWNGAYLO7YN6PR
              }}
              onSubmit={(values, ctx) => createPricingRule(values, ctx)}
            >
              {({ handleSubmit, values, setFieldValue }) => {
                const handleSelectCategory = (event, val) => {
                  setFieldValue('targetScopeId', val.props.value);
                  setCategory(
                    categories.find((p) => p.id === val.props.value) || {}
                  );
                };
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          required
                          type="text"
                          placeholder="Enter Primary POS Merchant ID"
                          name="merchantId"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            updateMerchantCode(values.merchantId, {
                              setFieldValue,
                            })
                          }
                          size="large"
                        >
                          Get Mapped Merchant
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          disabled
                          type="text"
                          placeholder="Business Name"
                          name="businessName"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          disabled
                          placeholder="POS System"
                          name="pos"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item style={{ position: 'relative' }}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          type="number"
                          removeArrows
                          name="amount"
                          variant="outlined"
                          required
                          label="Discount"
                          inputRef={amountRef}
                          style={{ width: 320 }}
                          InputProps={{
                            style: {
                              paddingRight: 150,
                              minWidth: 320,
                              width: '100%',
                            },
                          }}
                        />
                        <ToggleButtonGroup
                          exclusive
                          id="roleType"
                          value={values.discountType}
                          // onChange={(e, val) => {
                          //   setFieldValue('discountType', val);
                          //   if (document.activeElement !== amountRef.current)
                          //     amountRef.current.focus();
                          // }}
                          size="small"
                          style={{ position: 'absolute', right: 30, top: 15 }}
                        >
                          <ToggleButton
                            value={0}
                            style={{ padding: '5px 25px' }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setFieldValue('discountType', 0);
                              if (document.activeElement !== amountRef.current)
                                amountRef.current.focus();
                            }}
                          >
                            %
                          </ToggleButton>
                          <ToggleButton
                            value={1}
                            style={{ padding: '5px 25px' }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setFieldValue('discountType', 1);
                              if (document.activeElement !== amountRef.current)
                                amountRef.current.focus();
                            }}
                          >
                            {currencySymbol}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item>
                        <Field
                          component={Select}
                          as="select"
                          autoWidth={false}
                          fullWidth
                          required
                          label="Category"
                          name="targetScopeId"
                          variant="outlined"
                          onChange={(event, val) =>
                            handleSelectCategory(event, val)
                          }
                          style={{ width: 320 }}
                          placeholder="Category"
                        >
                          <MenuItem value={undefined}>---</MenuItem>
                          {categories.map(
                            (opt) =>
                              opt && (
                                <MenuItem value={opt.id} key={opt.id}>
                                  {opt.name}
                                </MenuItem>
                              )
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          trim
                          fullWidth
                          type="text"
                          label="Pricing Rule Id"
                          name="pricingRuleId"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Box my={3}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={7}>
                          <Box
                            style={{ border: '1px solid #ccc', height: 38 }}
                            p={1}
                          >
                            {values.amount > 0
                              ? `members will get ${
                                  values.discountType === 0
                                    ? `${values.amount}%`
                                    : currencyFormat(values.amount)
                                } off ${category.name || 'Whole Purchase'}`
                              : ''}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button
                            style={{ marginRight: 12 }}
                            onClick={handleSubmit}
                            loading={isLoading}
                            disabled={isLoading || values.pricingRuleId}
                            variant="contained"
                            color="primary"
                          >
                            Create Pricing Rule
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Box mt={3} mb={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            trim
                            fullWidth
                            type="text"
                            label="Discount Code"
                            name="code"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            type="number"
                            removeArrows
                            name="expirationDays"
                            variant="outlined"
                            required
                            label="Expiration Days"
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            style={{ marginRight: 12 }}
                            onClick={() =>
                              createDiscountCode(values, setFieldValue)
                            }
                            disabled={
                              isLoading ||
                              !values.pricingRuleId ||
                              !businessData.id
                            }
                            loading={isLoading}
                            variant="contained"
                            color="primary"
                          >
                            Create Discount Code
                          </Button>
                        </Grid>
                        <p>
                          {discountCode?.discountCode
                            ? `Discount Code Data: ${JSON.stringify(
                                discountCode
                              )}`
                            : ''}
                        </p>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
                code: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: '',
                code: '',
              }}
              onSubmit={(values, ctx) => verifyDiscountCode(values, ctx)}
            >
              {({ handleSubmit, values, setFieldValue }) => {
                return (
                  <Form>
                    <Divider />
                    <Box mt={3}>
                      <Typography variant="h4">
                        Verify Discount Code Data
                      </Typography>
                      <Grid container spacing={2} style={{ marginTop: 20 }}>
                        <Grid item xs={4}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            trim
                            fullWidth
                            required
                            type="text"
                            placeholder="Enter Secondary Merchant ID"
                            name="businessId"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            size="large"
                          >
                            Get Discount Code Infor
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            trim
                            fullWidth
                            disabled
                            type="text"
                            placeholder="Business Name"
                            name="businessName"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            trim
                            fullWidth
                            type="text"
                            label="Discount Code"
                            name="code"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <p>
                      {secondDiscountCode?.discountCode
                        ? `Discount Code Data: ${JSON.stringify(
                            secondDiscountCode
                          )}`
                        : ''}
                    </p>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DiscountCode;
