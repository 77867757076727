import React from 'react';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { alert, enterprises } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';

const EditCompany = ({ isOpen, closeModal, openAlert, itemSelected }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const phoneRegExp = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    address: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    city: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    state: Yup.string().required('Required'),
    zip: Yup.string().nullable(),
    country: Yup.string()
      .required('Required')
      .max(100, 'Password is too long - should be 100 chars max.'),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .required('Please enter website'),
    firstName: Yup.string()
      .required('Required')
      .max(100, 'Password is too long.'),
    lastName: Yup.string()
      .required('Required')
      .max(100, 'Password is too long.'),
    email: Yup.string().email('Invalid email'),
    password: Yup.string()
      .min(6, 'Password is too short.')
      .when('id', {
        is: (id) => !id,
        then: Yup.string().required('Required'),
      }),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  });

  const body = (
    <Box py={2}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          id: itemSelected.id || '',
          accountId: itemSelected.accountId || null,
          name: itemSelected.name || '',
          address: itemSelected.address || '',
          city: itemSelected.city || '',
          state: itemSelected.state || '',
          firstName: itemSelected.firstName || '',
          lastName: itemSelected.lastName || '',
          zipcode: itemSelected.zipcode || '',
          country: itemSelected.country || '',
          website: itemSelected.website || '',
          email: itemSelected.email || '',
          phone: itemSelected.phone || '',
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            setLoading(true);
            let response = '';
            if (values.id) {
              response = await dispatch(
                enterprises.actions.updateEnterprise(values)
              );
            } else {
              response = await dispatch(
                enterprises.actions.setEnterprise(values)
              );
            }
            if (response.payload) {
              let message = 'Enterprise created successfully';
              if (values.id) {
                message = 'Enterprise updated successfully';
              }
              openAlert({
                message,
                severity: 'success',
              });
              resetForm();
              closeModal(true);
            } else if (response.error && response.error.message) {
              openAlert({
                message: response.error.message || 'Oops something went wrong',
                severity: 'error',
              });
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }

          return true;
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <Box py={2}>
                <Grid container spacing={2}>
                  <Grid container item spacing={4} xs={12}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        size="small"
                        label="Enterprise Name"
                        type="text"
                        placeholder="Enterprise Name"
                        name="name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        placeholder="First Name"
                        size="small"
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={4} xs={12}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        size="small"
                        label="Address"
                        type="text"
                        placeholder="Address"
                        name="address"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Last name"
                        placeholder="Last Name"
                        name="lastName"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={4} xs={12}>
                    <Grid item xs={6} justifyContent="space-between">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            required
                            size="small"
                            type="text"
                            label="City"
                            placeholder="City"
                            name="city"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            required
                            type="text"
                            size="small"
                            label="State"
                            placeholder="State"
                            name="state"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CustomInputField
                            customInput={Field}
                            component={TextField}
                            fullWidth
                            size="small"
                            label="Zipcode"
                            type="text"
                            placeholder="Zipcode"
                            name="zip"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Email"
                        placeholder="Email"
                        name="email"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={4} xs={12}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Country"
                        placeholder="Country"
                        name="country"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Phone"
                        placeholder="Phone"
                        name="phone"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={4} xs={12}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        required
                        type="text"
                        size="small"
                        label="Website"
                        placeholder="Website"
                        name="website"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} style={{ textAlign: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={handleSubmit}
                  size="small"
                  margin="auto"
                >
                  Save details
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle textAlign="center">Enterprise Edit</DialogTitle>
      <DialogContent dividers>{body}</DialogContent>
    </Dialog>
  );
};

EditCompany.propTypes = {
  openAlert: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemSelected: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
