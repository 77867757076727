/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { useDebounce } from 'use-debounce';
import { TransactionTable } from '../../../custom';
import { coupon, franchises } from '../../../../state';
import searchIcon from '../../../../images/search.png';

const COUPON = 2;

const colsTitles = [
  'Merchant Name',
  'Merchant ID',
  'Created DateTime',
  'Max Redemptions',
  'Valid From DateTime',
  'Expiration DateTime',
  'Updated DateTime',
  'POS System',
  'Code#',
];

const rowKeys = [
  'businessName',
  'businessId',
  'createdAt',
  'maxRedemptions',
  'validFrom',
  'expiresAt',
  'updatedAt',
  'partner',
  'code',
];

// eslint-disable-next-line react/prop-types
const ValidateCoupon = () => {
  const [params, setParams] = React.useState({ limit: 10, page: 1 });
  const [keyword, setKeyword] = React.useState('');
  const [name, setName] = React.useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector(coupon.selectors.selectIsLoading);
  const selectValidate = useSelector(coupon.selectors.selectValidateCoupon);
  const { data } = useSelector(franchises.selectors.selectFranchisesList);

  const getValidateCoupon = (p) => {
    if (p?.keyword === '' || !params?.keyword) {
      return;
    }
    dispatch(coupon.actions.getValidateCoupon(p || params));
  };

  const [searchParams] = useDebounce(params, 700);

  const mods = {
    createdAt: (val) => moment(val).format('MMMM DD YYYY, HH:mm:ss'),
    expiresAt: (val) => moment(val).format('MMMM DD YYYY, HH:mm:ss'),
    updatedAt: (val) => moment(val).format('MMMM DD YYYY, HH:mm:ss'),
    maxRedemptions: (val) => (val === -1 ? 'Unlimited' : val),
  };

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
    getValidateCoupon(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    setParams(p);
    getValidateCoupon(p);
  };
  const pagination = {
    total: selectValidate.total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const search = () => {
    setParams({
      ...params,
      keyword,
    });
  };

  const getGroupFranchiseName = useCallback(
    (e) => {
      const franchise = data?.find((opt) => opt?.id === e.target.value);
      setParams({
        ...params,
        franchiseId: e.target.value,
      });
      setName(franchise?.name || '');
    },
    [params]
  );

  const searchBox = useCallback(() => {
    return (
      <Grid container spacing={2} alignItems="flex-end" className="checkD">
        <Grid item xs={12} md={5}>
          <FormControl fullWidth>
            <Select
              trim
              fullWidth
              required
              placeholder="Select Primary Merchant"
              name="primaryMerchant"
              variant="outlined"
              onChange={(e) => getGroupFranchiseName(e)}
            >
              <MenuItem value="">---</MenuItem>
              {(data || []).map(
                (opt) =>
                  opt &&
                  opt?.type === COUPON && (
                    <MenuItem value={opt?.id} key={opt?.id}>
                      {opt.business?.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            type="text"
            disabled
            placeholder="Group Name / Franchise Name"
            name="name"
            variant="outlined"
            value={name}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={5}>
          <TextField
            value={keyword}
            fullWidth
            required
            placeholder="Coupon Code *"
            type="string"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="keyword"
            onChange={(val) => {
              setKeyword(val.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => search()}
                    style={{ marginRight: -14 }}
                  >
                    <img
                      src={searchIcon}
                      alt="search"
                      style={{
                        width: 25,
                        height: 25,
                        paddingTop: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }, [search, params]);

  useEffect(() => {
    search();
  }, [keyword]);

  useEffect(() => {
    if (searchParams?.keyword?.length > 2) {
      getValidateCoupon(params);
    }
  }, [searchParams]);

  return (
    <Grid xs="12" lg="12" xl="12">
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={selectValidate?.data || []}
          mods={mods}
          isLoading={isLoading}
          searchBox={searchBox}
          pagination={pagination}
          noDataMsg="Hmm... Looks like there is no data."
          styles={{ alignItems: 'end' }}
        />
      </Box>
    </Grid>
  );
};

export default ValidateCoupon;
