import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ResponsiveBar } from '@nivo/bar';

const ChartSection = ({ data }) => {
  const commonProps = {
    height: 400,
    margin: { top: 20, right: 10, bottom: 50, left: 60 },
    padding: 0.2,
    colors: '#8BC8C0',
    enableLabel: false,
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `Merchants`,
      legendPosition: 'middle',
      legendOffset: -50,
      minValue: -1,
    },
  };

  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    if (data) {
      setChartData(
        data.slice(0, 12).map((el) => ({
          ...el,
        }))
      );
    }
  }, [data]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent style={{ height: '400px' }}>
            {!data?.length ? (
              <Skeleton
                height="100%"
                width="100%"
                style={{ transform: 'none' }}
              />
            ) : (
              <ResponsiveBar
                {...commonProps}
                indexBy="month"
                keys={['square', 'clover']}
                data={chartData}
                groupMode="grouped"
                colors={{ scheme: 'nivo' }}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent style={{ height: '400px' }}>
            {!data?.length ? (
              <Skeleton
                height="100%"
                width="100%"
                style={{ transform: 'none' }}
              />
            ) : (
              <ResponsiveBar
                {...commonProps}
                indexBy="month"
                keys={['total']}
                data={chartData}
                maxBarWidth={0.3}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

ChartSection.defaultProps = {
  data: [],
};

ChartSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.any]),
};

export default ChartSection;
