/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  Divider,
  withStyles,
  makeStyles,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { getOr } from 'lodash/fp';
import { useDebounce } from 'use-debounce';
import { alert, business } from '../../../state';
import SEO from '../../seo';
import searchIcon from '../../../images/search.png';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  {
    id: 'enableCoupon',
    label: 'Coupon',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'enableCoupon', value)}
      />
    ),
  },
  {
    id: 'enableGiftCard',
    label: 'Giftcard',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'enableGiftCard', value)}
      />
    ), // Giftcard status cannot be toggled
  },
  {
    id: 'enableLoyaltyCard',
    label: 'Loyalty Card',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'enableLoyaltyCard', value)}
      />
    ),
  },
  {
    id: 'enableMembership',
    label: 'Membership',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'enableMembership', value)}
      />
    ),
  },
  {
    id: 'enableStoreCredit',
    label: 'Store credit',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'enableStoreCredit', value)}
      />
    ),
  },
  {
    id: 'enableTicket',
    label: 'Ticket',
    minWidth: 170,
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'enableTicket', value)}
      />
    ),
  },
  {
    id: 'email',
    label: 'Login ID',
    minWidth: 170,
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    borderRadius: 6,
  },
});

const MerchantsList = ({ data }) => {
  const [params, setParams] = React.useState({ limit: 5, page: 1 });
  const [keyword, setKeyword] = React.useState('');
  const [searchParams] = useDebounce(params, 700);

  const classes = useStyles();
  const [headers] = React.useState(columns);
  const dispatch = useDispatch();
  const merchantList = getOr([], 'data', data);
  const total = getOr(0, 'total', data);
  const openAlert = (message) =>
    dispatch(dispatch(alert.actions.open(message)));

  const search = () => {
    setParams({ ...params, page: 1, keyword });
  };

  // On First Load
  useEffect(() => {
    dispatch(business.actions.getMerchantList(params));
  }, []);

  useEffect(() => {
    if (searchParams?.keyword === '' || searchParams?.keyword?.length > 2) {
      dispatch(business.actions.getMerchantList(params));
    }
  }, [searchParams]);

  useEffect(() => {
    search();
  }, [keyword]);

  const handleChangePage = (event, page) => {
    setParams({ ...params, page: page + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({ ...params, limit: event.target.value, page: 1 });
  };

  const handleChange = async (row, type, value) => {
    // Get the current statuses by id
    const {
      enableCoupon,
      enableLoyaltyCard,
      enableMembership,
      enableStoreCredit,
      enableTicket,
    } = row;
    const body = {
      id: row.id,
      enableCoupon,
      enableLoyaltyCard,
      enableMembership,
      enableStoreCredit,
      enableTicket,
    };

    const response = await dispatch(
      business.actions.setServiceStatus({
        ...body,
        [type]: !value,
      })
    );

    if (response.error) {
      openAlert({
        message: response.error.message || 'Unable to save',
        severity: 'error',
      });
    } else if (response.payload) {
      openAlert({
        message: 'Service status changed successfully!',
        severity: 'success',
      });
    }
    setParams({ ...params });
  };

  const searchBox = () => (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          value={keyword}
          fullWidth
          placeholder="Merchant name, login"
          type="string"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          name="keyword"
          onChange={(val) => {
            setKeyword(val.target.value);
          }}
          style={{ minWidth: 300 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => search()}
                  style={{ marginRight: -14 }}
                >
                  <img
                    src={searchIcon}
                    alt="search"
                    style={{
                      width: 25,
                      height: 25,
                      paddingTop: 2,
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  // Get Merchant Data.
  return (
    <>
      <SEO title="Merchants List" />
      <Box pb={2}>
        <Typography variant="h5">Merchants List</Typography>
      </Box>
      <Divider />
      <Box py={2}>
        <Paper className={classes.root}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item style={{ flexGrow: 1 }}>
              <Box mb={2}>{searchBox?.()} </Box>
            </Grid>
            <Grid item style={{ alignItems: 'flex-end' }}>
              <Box mb={2}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={total}
                  rowsPerPage={params.limit}
                  page={params.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {merchantList.map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {headers.map((header) => {
                        const value = row[header.id];
                        return (
                          <StyledTableCell key={header.id} align={header.align}>
                            {header.format
                              ? header.format(row, value, handleChange)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

MerchantsList.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  data: business.selectors.selectMerchantsList(state),
});

export default connect(mapStateToProps)(MerchantsList);
