import React, { useEffect } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { alert, enterprises } from '../../../../state';

const columns = [
  {
    id: 'categoryName',
    label: 'Category',
  },
  {
    id: 'businessName',
    label: 'Merchant Name',
  },
  { id: 'giftCardName', label: 'Gift Card Name' },
  { id: 'city', label: 'City' },
  { id: 'state', label: 'State' },
  { id: 'country', label: 'Country' },
  {
    id: 'isEnterprise',
    label: 'Enterprise GC Define',
    format: (row, value) => (value ? 'Yes' : 'No'),
  },
  {
    id: 'upmapMerchant',
    label: 'Unmap Merchant',
    format: (row, value, handleChange) => (
      <Switch
        checked={value}
        onChange={() => handleChange(row, 'upmapMerchant', value)}
      />
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    boxShadow: theme.shadows[5],
    right: 'auto',
    minWidth: '700px',
    height: '600px',
    bottom: 'auto',
    marginRight: '-50%',
    background: 'transparent',
    backgroundColor: '#fff',
    border: '1px solid #000',
    padding: theme.spacing(4, 4),
    overflow: 'scroll',
    // transform: 'translate(-50%, -50%)',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Modal.setAppElement('#___gatsby');

const CreateMapMerchant = ({ isOpen, closeModal, openAlert, enterpriseId }) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = React.useState('');
  const [merchants, setMerchants] = React.useState([]);
  const [merchantsFilter, setMerchantsFilter] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedMerchants, setSelectedMerchants] = React.useState([]);
  const [headers] = React.useState(columns);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleGetList = async () => {
    const response = await dispatch(
      enterprises.actions.getMerchants({ enterpriseId })
    );

    const items = [];
    // eslint-disable-next-line array-callback-return
    response.payload.map((i) => {
      const item = {
        categoryName: i.business.category.name,
        businessName: i.business.name,
        giftCardName: i.name,
        businessId: i.business.id,
        id: i.id,
        isEnterprise: i.isEnterprise,
        city: i.business.businessOwners?.[0]?.account?.city,
        state: i.business.businessOwners?.[0]?.account?.state,
        country: i.business.businessOwners?.[0]?.account?.country,
        keyword: i.business.name + i.business.category.name,
      };
      items.push(item);
    });

    setMerchants(items);
    setMerchantsFilter(items);
  };

  useEffect(() => {
    handleGetList();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleChange = async (row, type, value) => {
    let selectedMerchantsItems = selectedMerchants;
    const item = selectedMerchantsItems.find(
      (i) => i.giftCardConfigId === row.id
    );

    if (item) {
      selectedMerchantsItems = selectedMerchantsItems.filter(
        (i) => i.giftCardConfigId !== row.id
      );
    } else {
      selectedMerchantsItems.push({
        giftCardConfigId: row.id,
        businessId: row.businessId,
      });
    }

    setSelectedMerchants(selectedMerchantsItems);
  };

  const handleTextFieldChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    const search = new RegExp(keyword, 'i'); // prepare a regex object
    const merchantsF = merchants.filter((item) => search.test(item.keyword));
    setMerchantsFilter(merchantsF);
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    if (!selectedMerchants.length) {
      openAlert({
        message: 'No merchant has been selected yet',
        severity: 'error',
      });
      return false;
    }

    try {
      setLoading(true);
      const formData = { enterpriseId, merchants: selectedMerchants };
      const response = await dispatch(
        enterprises.actions.setEnterpriseAssociateByEnterpriseId(formData)
      );
      if (response.payload) {
        const message = 'Map Merchants success';

        openAlert({
          message,
          severity: 'success',
        });
        handleGetList();
        closeModal(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container justifyContent="center" md={12} spacing={2}>
        <Grid container item md={12}>
          <Grid container item md={6} xs={6} spacing={2}>
            <Grid item md={8} xs={8}>
              <TextField
                fullWidth
                placeholder="Merchant Name, City, State, Country"
                value={keyword}
                onChange={handleTextFieldChange}
                name="name"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={4} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                size="small"
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchantsFilter.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {headers.map((header) => {
                          const value = row[header.id];
                          return (
                            <TableCell key={header.id} align={header.align}>
                              {header.format
                                ? header.format(row, value, handleChange)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
            size="small"
          >
            Map
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {body}
    </Modal>
  );
};

CreateMapMerchant.propTypes = {
  openAlert: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  enterpriseId: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateMapMerchant);
