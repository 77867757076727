/* eslint-disable react/prop-types */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { alert, franchises } from '../../../../state';
import { TransactionTable } from '../../../custom';
import CustomInputField from '../../../custom/CustomInputField';

const colsTitles = [
  '#',
  'Merchant Id',
  'Merchant Name',
  'Gift Card #',
  'Gift Card Id',
  'Created At',
  'Current Balance',
];

const rowKeys = [
  'index',
  'businessId',
  'merchantName',
  'code',
  'id',
  'createdAt',
  'amount',
];

const SquareGiftCardsList = () => {
  const dispatch = useDispatch();
  const openAlert = (data) => dispatch(dispatch(alert.actions.open(data)));
  // const data = useSelector(card.selectors.selectPhysicalGiftCardList);

  const refForm = useRef();
  const refLink = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState('USD');

  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  const getFranchise = async (values) => {
    if (!values.businessId) return;
    const resp1 = await dispatch(
      franchises.actions.getFranchise({
        businessId: values.businessId,
        type: 0,
      })
    );
    if (resp1.error) {
      openAlert({
        message:
          (resp1.payload && resp1.payload.message) || 'Invalid merchant Id',
        severity: 'error',
      });
      setIsLoading(false);
      refForm.current?.setValues({
        merchantName: '',
        name: '',
      });
      return;
    }
    setCurrency(resp1.payload?.business?.currency || 'USD');
    refForm.current?.setValues({
      ...refForm.current?.values,
      merchantId: resp1.payload.business?.merchantId,
      merchantName: resp1.payload.business?.name,
      name: resp1.payload.name,
    });
    // eslint-disable-next-line consistent-return
    return resp1;
  };
  const getSquareGiftCard = async (values) => {
    setIsLoading(true);
    const resp1 = await getFranchise(values);

    const resp2 = await dispatch(
      franchises.actions.getSquareGiftCards({
        merchantId: values.merchantId ?? resp1.payload.business?.merchantId,
      })
    );
    if (resp2.error) {
      openAlert({
        message:
          (resp2.payload && resp2.payload.message) || 'Invalid merchant Id',
        severity: 'error',
      });
      setIsLoading(false);
      return;
    }
    setData(
      resp2.payload.data?.map((e, index) => ({
        ...e,
        index: index + 1,
        code: `sqgc://${e.code}`,
        businessId: resp1.payload.business?.id,
        merchantName: resp1.payload.business?.name,
        createdAt: moment(e.createdAt).format('MM/DD/YYYY, HH:mm:ss'),
      }))
    );
    setTimeout(() => setIsLoading(false), 200);
  };

  const onExportCSV = () => {
    refLink.current.link.click();
  };

  const headers = colsTitles.map((col, key) => ({
    label: col,
    key: rowKeys[key],
  }));

  const currencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    }).format(value);
  return (
    <>
      <Box py={2}>
        <Grid container spacing={2} style={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <Formik
              validationSchema={Yup.object({
                businessId: Yup.string().required('Required'),
              })}
              initialValues={{
                businessId: 'a6a900a9-5661-4721-a035-59469bd3027d',
                merchantName: '',
                name: '',
              }}
              onSubmit={getSquareGiftCard}
            >
              {(props) => {
                const { handleSubmit } = props;
                refForm.current = props;
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={2}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          required
                          type="text"
                          label="Enter merchant ID"
                          name="businessId"
                          variant="outlined"
                          onBlur={() => getFranchise(props.values)}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          type="text"
                          label="Merchant Name"
                          name="merchantName"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <CustomInputField
                          customInput={Field}
                          component={TextField}
                          fullWidth
                          type="text"
                          label="Group Name / Franchise"
                          name="name"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          size="large"
                        >
                          Get Active Gift Card from this merchant
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Box py={2}>
        <Grid item md={12} xs={12} lg={10} xl={8}>
          <CSVLink
            data={data}
            filename="giftCards.csv"
            className="hidden"
            ref={refLink}
            target="_blank"
            headers={headers}
          />
          <TransactionTable
            cols={colsTitles}
            rowKeys={rowKeys}
            data={data.slice(
              (params.page - 1) * params.pageSize,
              params.page * params.pageSize
            )}
            mods={{
              amount: (val) => currencyFormat(val.toFixed(2)),
            }}
            pagination={{
              total: data.length,
              pageSize: params.pageSize,
              page: params.page,
              handleChangePage: (event, page) =>
                setParams({ ...params, page: page + 1 }),
              handleChangeRowsPerPage: (event) =>
                setParams({ ...params, pageSize: event.target.value, page: 1 }),
            }}
            searchBox={() => (
              <Grid container spacing={2} md={8}>
                <Grid item>
                  <Typography
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#0969da',
                      float: 'left',
                    }}
                    disabled={data.length === 0}
                    onClick={onExportCSV}
                  >
                    Export in CSV
                  </Typography>
                </Grid>
              </Grid>
            )}
            isLoading={isLoading}
            noDataMsg="Hmm... Looks like there is no data."
          />
        </Grid>
      </Box>
    </>
  );
};

export default SquareGiftCardsList;
