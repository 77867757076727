/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, Select, TextField } from '@mui/material';
import moment from 'moment';
import SEO from '../../seo';
import { memberships } from '../../../state';
import { TransactionTable } from '../../custom';

const colsTitles = [
  'Secondary Merchant Name',
  'Program Name',
  'Member First Name',
  'Member Last Name',
  'Unique Discount Code',
  'Start Date',
  'End Date',
];

const rowKeys = [
  'businessName',
  'tier',
  'firstName',
  'lastName',
  'code',
  'createdAt',
  'expirationDate',
];

const SharedMembers = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    sharingStatus: 1,
  });
  const dispatch = useDispatch();

  const getSharedMembers = (p) => {
    dispatch(memberships.actions.getSharedMemberships(p || params));
  };

  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const { total, data } = useSelector(
    memberships.selectors.selectSharedMemberships
  );

  const primaryMerchants = useSelector(
    memberships.selectors.selectPrimaryMemberships
  );

  useEffect(() => {
    getSharedMembers();
  }, []);

  const mods = {
    createdAt: (val) => val && moment(`${val}Z`).format('MMMM DD YYYY'),
    expirationDate: (val) => val && moment(`${val}Z`).format('MMMM DD YYYY'),
  };

  const handleChangePage = async (event, page) => {
    const p = { ...params, page: page + 1 };
    setParams(p);
    getSharedMembers(p);
  };
  const handleChangeRowsPerPage = async (event) => {
    const p = { ...params, limit: event.target.value, page: 1 };
    console.log(p, event.target.value);
    setParams(p);
    getSharedMembers(p);
  };
  const pagination = {
    total,
    pageSize: params.limit,
    page: params.page,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  const searchBox = () => (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              trim
              fullWidth
              required
              placeholder="Select Primary Merchant"
              name="membershipPlanId"
              variant="outlined"
              style={{ height: 40 }}
              onChange={(e) =>
                setParams({ ...params, businessId: e.target.value })
              }
            >
              <MenuItem value="">---</MenuItem>
              {(primaryMerchants?.data || []).map(
                (opt) =>
                  opt && (
                    <MenuItem value={opt.business?.id} key={opt.business?.id}>
                      {opt.business?.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            required
            type="text"
            label="Program Name"
            size="small"
            name="name"
            onChange={(e) => setParams({ ...params, name: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={getSharedMembers}
            type="submit"
          >
            Search
          </Button>
        </Grid>
        {/* <Grid item> */}
        {/*   <DownloadCSV */}
        {/*     handleDownload={handleDownload} */}
        {/*     refLink={refLink} */}
        {/*     data={csv} */}
        {/*     filename="membership-list" */}
        {/*   /> */}
        {/* </Grid> */}
      </Grid>
    </Grid>
  );

  return (
    <Grid md={12} lg={8} xl={8}>
      <SEO title="Shared Memberships" />
      <Box py={2}>
        <TransactionTable
          cols={colsTitles}
          rowKeys={rowKeys}
          data={data || []}
          mods={mods}
          pagination={pagination}
          isLoading={isLoading}
          searchBox={searchBox}
          noDataMsg="Hmm... Looks like there is no data."
        />
      </Box>
    </Grid>
  );
};

export default SharedMembers;
