import React from 'react';
import {
  Grid,
  Box,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';

import { DeleteOutline, Add } from '@material-ui/icons';

const response = {
  items: [
    {
      name: 'John Doe',
      email: 'john@pintuna.com',
      role: 'Admin',
    },
    {
      name: 'Richard Denim',
      email: 'richard@pintuna.com',
      role: 'Marketing',
    },
    {
      name: 'Sam Rebrook',
      email: 'sam@pintuna.com',
      role: 'Manager',
    },
  ],
};

const UserInfoSection = () => {
  const [data, setData] = React.useState(response);

  const handleAddUser = () => {};
  const handleDeleteUser = (i) => {
    const { items } = data;
    items.splice(i, 1);
    setData({ items });
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Paper elevation={4} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map(({ name, email, role }) => {
                return (
                  <TableRow key={email}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{role}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={handleDeleteUser}
                        color="secondary"
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box pt={2}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserInfoSection;
